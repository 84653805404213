import React from 'react';


import Modal from 'antd/lib/modal'
import 'antd/lib/modal/style'
import Icon from 'antd/lib/icon'
import 'antd/lib/icon/style'
import message from 'antd/lib/message'
import 'antd/lib/message/style'
import { LoadingOutlined, CameraOutlined } from "@ant-design/icons"

import './profile.css';
import {getEmail, isLoggedIn} from "../../config/cookie";
import {backendPath, getBackendUrl} from "../../config/url";


export class PwdResetModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  handleCancel() {
    this.props.parent.setState({showModal: null});
  }
  handleClick(e) {
    if(!isLoggedIn()) {
      return;
    }
    const old = document.getElementById('old').value;
    const new1 = document.getElementById('new1').value;
    const new2 = document.getElementById('new2').value;
    if(new1 === old) {
      message.warning('新密码与旧密码一致');
      return ;
    }
    this.setState({
      processing: true
    });
    let xmlhttp=new XMLHttpRequest();
    let fd = new FormData();
    fd.append("email", getEmail());
    fd.append("code", old);
    fd.append("password", new1);
    xmlhttp.open(backendPath.user.resetPwd.method, getBackendUrl(backendPath.user.resetPwd.path), true);
    xmlhttp.withCredentials = true;
    xmlhttp.send(fd);
    let cur = this;
    xmlhttp.onreadystatechange = function(){
      if(xmlhttp.readyState === 4) {
        if(xmlhttp.status === 200 ) {
          const response = JSON.parse(xmlhttp.responseText);
          message.success(response['msg'])
        } else if(xmlhttp.status === 401){
          message.warn("请登录账号")
        } else {
          message.warning('密码修改失败');
        }
        cur.setState({
          processing: false
        });
      }
    };
  }
  render() {
    const modelWidth = 600;
    return (
      <Modal visible={this.props.parent.state.showModal === 'pwd'} footer={null} width={modelWidth} onCancel={this.handleCancel}>
        <div style={{textAlign:'center', color:'white', width:500, height:390, marginLeft:(modelWidth - 48 - 500) / 2}}>
          {/*title*/}
          <span style={{textAlign:'center', fontSize:20, display:'block'}}><b>重&nbsp;&nbsp;置&nbsp;&nbsp;密&nbsp;&nbsp;码</b></span>
          {/*原始邮箱*/}
          <div style={{marginTop:30, height:40, textAlign:'center', fontSize:20, opacity:0.8}}>
            <input autoComplete={'off'} className={'input-with-myborder'} placeholder={"原始密码"}
                   id={'old'} type={'password'} style={{width:500}}/>
          </div>
          {/*新密码*/}
          <div style={{marginTop:30, height:40, textAlign:'center', fontSize:20, opacity:0.8}}>
            <input autoComplete={'off'} className={'input-with-myborder'} placeholder={"新密码"}
                   id={'new1'} type={'password'} style={{width:500}}/>
          </div>
          <div style={{marginTop:30, height:40, textAlign:'center', fontSize:20, opacity:0.8}}>
            <input autoComplete={'off'} className={'input-with-myborder'} placeholder={"确认新密码"}
                   id={'new2'} type={'password'} style={{width:500}}/>
          </div>
          {/*重置按钮*/}
          <a href="#" className="btn btn-medium" style={{width:250, borderRadius:40, marginTop:50, fontSize:18, fontFamily:'PingFangSC-Regular'}}
            onClick={this.handleClick}
          >
            {this.state.processing ? <><LoadingOutlined/>&nbsp;&nbsp;</>:<></>}重&nbsp;&nbsp;&nbsp;置
          </a>
        </div>
      </Modal>
    )
  }
}
