import React from 'react';
import axios from "axios";
import {backendPath, getBackendUrl, s3UrlBase} from "../config/url";
import {message} from "antd";
import "../css/loading.css";
import { Input } from 'antd';
import { Button } from 'antd';
import {dateToString} from "../config/transform";
import { MessageOutlined } from "@ant-design/icons";
import {StarComponent} from "./star";
import {isLoggedIn} from "../config/cookie";
import Avatar from "./img/avatar.jpg";
const { TextArea } = Input;



class Comment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      comments: null,
      placeholder: "你的评论 ...",
      commentObjectID: null,
      index: -1,
      posting: false
    };
    const current = this;
    axios.get(
      getBackendUrl(backendPath.comment.list.path.replace("{0}", current.props.id))
    ).then(function (response) {
      current.setState({comments: response.data.comments})
    });
  }

  handleStartComment(index, commentObjectID, replyTo) {
    this.setState({
      index: index, commentObjectID: commentObjectID, comment: "",
      placeholder: "回复" + replyTo.toString()
    });
    // window.location.href = "#comment";
  }

  handleComment() {
    if(!isLoggedIn()) {
      message.warning("请登录账号。")
      return;
    }
    let comments = this.state.comments, index = this.state.index, current = this;
    current.setState({posting: true})
    axios.post(
      getBackendUrl(backendPath.comment.create.path),{
        receiver: current.state.commentObjectID,
        content: current.state.comment
      }
    ).then(function (response) {
      if(index === -1 || index >= comments.length) {
        comments = [response.data].concat(comments)
      } else {
        comments[index].sub_comments.push(response.data)
      }
      current.setState({commentObjectID: null, placeholder: "你的评论 ...", comment: "", index: -1})
      current.setState({comments: comments, posting: false});
      message.success("评论成功");
    }).catch(function (e) {
      current.setState({posting: false})
      message.warning("暂时无法留言 ...");
    });
  }

  render() {
    const rootID = this.props.id, commentObjectID = this.state.commentObjectID, current = this;
    const commentList = [], comments = this.state.comments;
    if(comments === null) return <></>;
    const commentInputTextArea = <>
      <TextArea
        value={this.state.comment}
        onChange={(e) => this.setState({comment: e.target.value})}
        placeholder={this.state.placeholder}
        autoSize={{ minRows: 2, maxRows: 10 }}
        onClick={
          () => {
            if(current.state.commentObjectID === null) {
              current.setState({commentObjectID: rootID, placeholder: "你的评论 ...", index: -1})
            }
          }
        }
      />
      <div style={{textAlign:'right', margin:15, display: current.state.commentObjectID !== null ? "block": "none"}}>
        <Button style={{width:80, marginRight: 20}}
                onClick={() => current.setState({commentObjectID: null, placeholder: "你的评论 ...", comment: "", index: -1})}
        >
          取消
        </Button>
        {
          this.state.comment ?
            <Button type="primary" style={{width:80}} onClick={() => this.handleComment()}>评论</Button>:
            <Button type="primary" style={{width:80}} disabled>评论</Button>
        }
      </div>
    </>;
    for(let i = 0; i < comments.length; i ++) {
      const comment = comments[i], subCommentList = [], subComments = comment.sub_comments;
      for(let j = 0; j < subComments.length; j ++) {
        const subComment = subComments[j];
        const avatar = subComment.avatar ? s3UrlBase + subComment.avatar : Avatar;
        subCommentList.push(
          <div style={{paddingLeft:30, position:'relative'}}>
            <img src={avatar} style={{width:25, borderRadius:15, position:'absolute', top: 0, left:0}}/>
            <p>
              <span style={{fontSize:16, fontWeight:600, opacity: 0.8}}>
                {subComment.creator}
                <span style={{fontWeight: 400, fontSize: 14}}>
                  &nbsp;&nbsp;回复&nbsp;&nbsp;{subComment.reply_to}&nbsp;&nbsp;&nbsp;
                </span>
              </span>
              <span style={{fontSize:12, opacity: 0.7}}>{dateToString(new Date(subComment.add_time))}</span>
            </p>
            <p style={{wordWrap:'break-word', marginBottom:5}}>
              {subComment.content}
            </p>
            <p style={{marginBottom:15}}>
              <StarComponent isStar={subComment.is_star} id={subComment.id} starCount={subComment.star_count}/>
              <span onClick={
                        () => this.handleStartComment(i, subComment.id, subComment.creator + ":" + subComment.content
                      )}
                    style={{cursor:'pointer'}}
              >
                <MessageOutlined />&nbsp;&nbsp;回复
              </span>
            </p>
            {
              subComment.id === commentObjectID ?
                commentInputTextArea: <></>
            }
          </div>
        )
      }
      const avatar = comment.avatar ? s3UrlBase + comment.avatar : Avatar;
      commentList.push(
        <div style={{paddingLeft:40, position:'relative'}}>
          <img src={avatar} style={{width:30, borderRadius:15, position:'absolute', top: 0, left:0}}/>
          <p>
            <span style={{fontSize:16, fontWeight:600, opacity: 0.8}}>{comment.creator}</span>&nbsp;&nbsp;&nbsp;
            <span style={{fontSize:12, opacity: 0.7}}>{dateToString(new Date(comment.add_time))}</span>
          </p>
          <p style={{wordWrap:'break-word', marginBottom:5}}>
            {comment.content}
          </p>
          <p style={{marginBottom: 15}}>
            <StarComponent isStar={comment.is_star} id={comment.id} starCount={comment.star_count}/>
            <span onClick={
                    () => this.handleStartComment(i, comment.id, comment.creator + ":" + comment.content)
                  }
                  style={{cursor:'pointer'}}
            >
              <MessageOutlined />&nbsp;&nbsp;回复
            </span>
          </p>
          {
              comment.id === commentObjectID ?
                commentInputTextArea: <></>
            }
          <div style={{width:'100%'}}>{subCommentList}</div>
        </div>
      )
    }
    return(
      <>
        <a name="comment"></a>
        <div style={{width:'90%', margin:'0 auto', padding:"30px 0"}}>
          {commentObjectID === null || commentObjectID === rootID ? commentInputTextArea: <></>}
          {
            commentList.length > 0 ?
              <p style={{fontSize:20, fontWeight:600, marginTop:30, marginBottom:20}}>精彩评论</p>:
              <p style={{fontSize:20, fontWeight:600, marginTop:30, marginBottom:20}}>暂无评论</p>
          }
          {commentList}
          <div>

          </div>
        </div>
      </>
    )
  }
}
export default Comment;
