import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {message, Input, Button} from "antd";
import axios from "axios";
import {backendPath, getBackendUrl} from "../config/url";
import { LoadingOutlined } from "@ant-design/icons"



class Donate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      coins: 5,
      inputCoins: "5",
      creating: false
    };
    const current = this;
    axios.get(
      getBackendUrl(backendPath.subscription.list.path)
    ).then(function (response) {
      current.setState({subscription: response.data})
    });
  }
  handleDonate() {
    let httpRequest = new XMLHttpRequest(), cur = this;
    cur.setState({creating: true});
    httpRequest.open(
      backendPath.donate.create.method,
      getBackendUrl(backendPath.donate.create.path), true);
    httpRequest.withCredentials = true;
    let fd = new FormData();
    fd.append("coins", cur.state.coins);
    httpRequest.send(fd);
    httpRequest.onreadystatechange = function () {
        if (httpRequest.readyState === 4) {
          const obj = JSON.parse(httpRequest.responseText);
          if (httpRequest.status === 200) {
            window.open(obj['url'], "_self")
          } else if (httpRequest.status === 400) {
            message.warning(obj['msg']);
            cur.setState({creating: false});
          } else {
            message.warning("赠送失败");
            cur.setState({creating: false});
          }
        }
    };
  }
  render() {
    let className = 'section-padding gray-bg', title = "创作";
    if(this.props.className) {
      className = this.props.className;
    }
    if(this.props.title) {
      title = this.props.title;
    }
    const cur = this;
    return(
      <section className={className}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title text-center" style={{fontSize:25, fontWeight:600}}>
                  <span>感恩相遇，请Andy喝杯咖啡</span><br/><br/>$&nbsp;
                  <Input style={{display:'inline', width:70, boarder:0, marginTop:-20, fontSize:26, padding:4, height:35}}
                         value={this.state.inputCoins}
                         onChange={(e) => {
                           cur.setState({inputCoins: e.target.value})
                           let c = Number(e.target.value);
                           if(c) {
                             if(c <= 0) message.warn("没有不要钱的咖啡哦");
                             else cur.setState({coins: c});
                           }
                         }}
                  />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Button type="primary" onClick={this.handleDonate.bind(this)} style={{width:180, fontSize:22, height:50}}>
                    {this.state.creating ? <><LoadingOutlined/>&nbsp;&nbsp;</> : ""}送给Andy
                  </Button>

                </div>
              </div>
            </div>
          </div>
        </section>

    )
  }
}
export default Donate;
