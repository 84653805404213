import axios from 'axios';

export const env = "prod";

export const settings = {
  dev: {
    backendUrlBase: "http://127.0.0.1:8000/api",
    frontendUrlBase: "http://127.0.0.1:3000",
    domain: "127.0.0.1",
    s3UrlBase: "https://d39kdfcolhpeir.cloudfront.net/"
  },
  prod: {
    backendUrlBase: "https://www.longwayinvesting.com/api",
    frontendUrlBase: "https://www.longwayinvesting.com",
    domain: ".longwayinvesting.com",
    s3UrlBase: "https://d39kdfcolhpeir.cloudfront.net/"
  }
};

axios.defaults.withCredentials = true;

export let headerHeight = 60;
