import React from 'react';
import HeaderComponent from "../../components/header";
import axios from "axios";
import {backendPath, getBackendUrl, getQueryVariable} from "../../config/url";
import {message} from "antd";
import {headerHeight} from "../../config";
import FooterComponent from "../../components/footer";
import banner1 from "../../img/slides/1.jpg";
import {breakText} from "../../config/text";
import Channel from "../home/channel";
import Subscribe from "../home/subscribe";




class ChannelPage extends React.Component {
  constructor(props) {
    super(props);
    const channelID = this.props.match.params.channel_id;
    this.state = {
      detail: {
        id: channelID,
        title: "美股大鲨鱼订阅频道",
        short_desc: "",
        cover_url: "",
        video_list: []
      },
      loading: false,
      page: 2
    };
    const current = this;
    axios.get(
      getBackendUrl(backendPath.channel.retrieve.path.replace("{0}", channelID))
    ).then(function (response) {
      current.setState({detail: response.data.result})
    });
  }

  handleLoadMore() {
    if(this.state.page <= 0) return;
    const page = this.state.page;
    this.setState({loading: true});
    const current = this;
    current.setState({loading:true});
    axios.get(
      getBackendUrl(backendPath.video.list.path)
            + "?page_size=12&page=" + (page).toString() + "&channel=" + current.props.match.params.channel_id
    ).then(function (response) {
      let videos = response.data.result;
      let detail = current.state.detail;
      detail.video_list = detail.video_list.concat(videos);
      current.setState({
          loading: false,
          page: page + 1,
          detail: detail
        })
    }).catch(
      function (e) {
        current.setState({page: 0, loading: false})
      }
    );
  }

  render() {
    const channel = this.state.detail;
    let loadMore = <div style={{textAlign:'center'}}>视频全都加载完啦</div>;
    if(this.state.loading) {
      loadMore = <div className={'loading'} style={{marginTop: '5em', marginBottom:'5em'}}></div>
    } else if(this.state.page > 0) {
      loadMore = <div style={{textAlign:'center'}} onClick={() => this.handleLoadMore()}><a>加载更多</a></div>;
    }
    if(channel.video_list.length <= 0) {
      loadMore = <></>;
    }
    return(
      <>
        <HeaderComponent/>
        <Channel channel={channel} index={1} cols={4} className={"col-sm-3"}/>
        <section className={"section-padding gray-bg"}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title text-center">
                  {loadMore}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Subscribe/>
        <FooterComponent/>
      </>
    )
  }
}
export default ChannelPage;
