import React from 'react';

import message from 'antd/lib/message'
import 'antd/lib/message/style'
import Icon from 'antd/lib/icon'
import 'antd/lib/icon/style'
import Modal from 'antd/lib/modal'
import 'antd/lib/modal/style'
import { LoadingOutlined } from "@ant-design/icons"



import Input from 'antd/lib/input'
import 'antd/lib/input/style'
import {isLoggedIn, setCookie} from "../config/cookie";

import './index.css'
import {backendPath, getBackendUrl} from "../config/url";


import { LikeOutlined } from "@ant-design/icons";
import axios from "axios";


export class StarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      starCount: this.props.starCount,
      isStar: this.props.isStar
    }
  }

  handleStar() {
    if(!isLoggedIn()) {
      message.warn("请登录账号");
      return ;
    }
    const current = this;
    axios.post(
      getBackendUrl(backendPath.star.do.path), {
        receiver: this.props.id
      }
    ).then(function (response) {
      if(response.status === 201) {
        current.setState({
          isStar: !response.data.is_deleted,
          starCount: current.state.starCount + (response.data.is_deleted ? -1: 1)
        })
      }
    });
  }


  render() {
    return(
      <>
        <LikeOutlined onClick={() => this.handleStar()}
          style={{cursor:'pointer', color:this.state.isStar ? 'red':'gray'}}
        />
        &nbsp;&nbsp;
        {this.state.starCount}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </>
    );
  }
}
