import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/fancybox/jquery.fancybox.css";
import "../../css/flexslider.css";
import "../../css/style.css";
import {breakText} from "../../config/text";
import '../../index.css'
import { PlayCircleOutlined } from '@ant-design/icons';
import {frontendPath, getFrontendUrl} from "../../config/url";
import defaultVideoCover from '../../img/default_video_cover.jpeg';


class Channel extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let channel = this.props.channel, index = this.props.index, cols = this.props.cols, className = this.props.className;
    let style = {};
    if(channel.video_show_cols === 3) {cols = 3; className = "col-sm-4";}
    else if(channel.video_show_cols === 4) {cols = 4; className = "col-sm-3"; style = {padding: '0 8px 0 8px'}}
    else if(channel.video_show_cols === 6) {cols = 6; className = "col-sm-2"; style = {padding: '0 5px 0 5px'}}
    const title = channel.title, shortDesc = breakText(channel.short_desc), videoList = channel.video_list;
    const videoDisplay = [];
    for(let i = 0; i < videoList.length; i += cols) {
      const videoLine = [];
      for(let index = 0; index < cols && i + index < videoList.length; index ++) {
        const video = videoList[i + index];
        videoLine.push(
          <div className={className +" md-margin-bottom-40"} style={style}>
            <div style={{position:'relative', cursor:'pointer'}}
                 onClick={() => window.open(
                    getFrontendUrl(frontendPath.video.replace('{0}', video.id).replace('{1}', channel.id)
                   ), '_blank')
                 }
            >
              <img className="img-responsive" src={video.cover_url ? video.cover_url: defaultVideoCover} alt=""/>
              <div className={'video-hover'} style={{
                width:'100%', height:'100%', zIndex:2, position:'absolute', left:0, top:0, padding:"0 15px", opacity:0,
                background:'black', display: "table-cell", verticalAlign: 'middle', textAlign: 'center'
              }}>
                <p style={{position:'absolute', top:'50%', left:'50%', fontSize:44, margin: -22}}><PlayCircleOutlined/></p>
              </div>
            </div>
            <div style={{position:'relative'}}>
              <div style={{
                position:'absolute', right: 5, top: -30, width:60, background:'rgb(0, 0, 0, 0.8)', textAlign:'center',
                fontSize:16, fontWeight: 600, color:'white'
              }}>
                {Math.floor(video.duration / 60).toString() + ":" + (video.duration % 60).toString()}
              </div>
              <h3 style={{lineHeight:'23px', marginTop:5}}>{video.title}</h3>
            </div>
            <p style={{color:'black'}}>
              {video.days === 0 ? "今天更新" : video.days.toString() + "天前更新"}
              {/*&nbsp;-&nbsp;{video.view_count}次观看*/}
            </p>
          </div>
        )
      }
      videoDisplay.push(
        <div className="row service-v1" style={{marginBottom:10}}>{videoLine}</div>
      )
    }
    return(
      <section className={index % 2 === 0 ? "section-padding": "section-padding gray-bg"}>
          <div className="container">
            {
              videoDisplay.length > 0 ?
                <>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="section-title text-center">
                        <h2><span className="coloured">{title}</span></h2>
                        <p style={{fontSize:16, fontWeight: 700}}>{shortDesc}</p>
                      </div>
                    </div>
                  </div>
                  {videoDisplay}
                </>: <div className={'loading'} style={{marginTop: '5em', marginBottom:'5em'}}></div>
            }
          </div>
        </section>
    )
  }
}
export default Channel;
