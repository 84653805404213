import React from 'react';
import HeaderComponent from "../../components/header";
import axios from "axios";
import {backendPath, getBackendUrl} from "../../config/url";
import {message} from "antd";
import {headerHeight} from "../../config";
import FooterComponent from "../../components/footer";
import Article from "./article";
import emptyCourseListPict from './pict/emptyCourseList.png'



class CommunityPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      articles: [],
      pageSize: 2,
      page: 1,
      loading: false,

    };
    const current = this;
    axios.get(
      getBackendUrl(backendPath.article.list.path)
    ).then(function (response) {
      current.setState({articles: response.data.result})
    });
    this.handleLoadMore = this.handleLoadMore.bind(this);
  }
  handleLoadMore() {
    const current = this;
    current.setState({loading:true});
    axios.get(
      getBackendUrl(backendPath.article.list.path)
            + "?page_size=" + current.state.pageSize.toString()
            + "&page=" + (current.state.page + 1).toString()
    ).then(function (response) {
      let articles = response.data.result;
      current.setState({
          loading: false,
          page: current.state.page + 1,
          articles: current.state.articles.concat(articles)
        })
    }).catch(
      function (e) {
        current.setState({page: 0, loading: false})
      }
    );
  }
  render() {
    const articleListSection = [], articles = this.state.articles;
    let display = <div className={'loading'} style={{marginTop: '5em', marginBottom:'5em'}}></div>;
    if(articles !== null) {
      if(articles.length === 0) {
        display = <div style={{textAlign:'center', paddingTop: 50}}>
          <img src={emptyCourseListPict} style={{width:150, height:100}} alt=""/><br/>
          <p style={{marginTop:20, fontSize:20, opacity:0.6}}>订阅俱乐部频道或会员频道后进入社区</p>
        </div>
      } else {
        for(let article of articles) {
          articleListSection.push(<Article article={article} showAll={false}/>)
        }
        let loadMore = <div style={{textAlign:'center'}}>帖子全都加载完啦</div>;
        if(this.state.loading) {
          loadMore = <div className={'loading'} style={{marginTop: '5em', marginBottom:'5em'}}></div>
        } else if(this.state.page > 0) {
          loadMore = <div style={{textAlign:'center'}} onClick={() => this.handleLoadMore()}><a>加载更多</a></div>;
        }
        display = <>
          <div className="container" style={{minHeight:'100%'}}>
            <div style={{width:'90%', margin:"0 auto"}}>
              {articleListSection}
            </div>
          </div>
          {loadMore}
        </>
      }
    }



    return(
      <>
        <HeaderComponent/>
        <section className="section-padding" style={{background:'white', minHeight:'80%'}}>
          {display}
        </section>
        <FooterComponent/>
      </>
    )
  }
}
export default CommunityPage;
