

import React from 'react';
import axios from "axios";
import {backendPath, frontendPath, getBackendUrl, getFrontendUrl, getQueryVariable} from "../../config/url";
import {message} from "antd";
import {headerHeight} from "../../config";
import "video-react/dist/video-react.css"
import {Player, BigPlayButton, PlaybackRateMenuButton, ControlBar, VolumeMenuButton} from "video-react/lib"
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import viewPict from './pict/view.png'
import './scroll.css'
import {StarComponent} from "../../components/star";
import Comment from "../../components/comment";
import Donate from "../../components/donate";

const videoDivClass = "video-react-controls-enabled";


class PlayerComponent extends React.Component {
  constructor(props) {
    super(props);
    const video_id = this.props.videoID;
    this.state = {
      detail: {
          id: video_id,
          channels: [],
          title: "美股大鲨鱼",
          short_desc: "",
          long_desc: "",
          cover_url: "",
          address: "",
          signed_address: null,
          duration: 120,
          add_time: null,
          star_count: 0,
          is_star: false,
          view_count: 0,
          comment_permit: true
      },
      playList: [],
      player: null
    };
    const current = this;
    axios.get(
      getBackendUrl(backendPath.channel.retrieve.path.replace("{0}", getQueryVariable('channel')))
    ).then(function (response) {
      current.setState({playList: response.data.result.video_list})
    });
    axios.get(
      getBackendUrl(backendPath.video.retrieve.path.replace("{0}", video_id))
    ).then(function (response) {
      const detail = response.data;
      current.setState({detail: response.data})
      document.oncontextmenu = function () {
        console.log("别右击了 ...")
        return false;
      }
    });
  }
  componentDidMount() {
    try {
      if(this.player !== null) this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    } catch (e) {

    }
  }

  handleStateChange(state) {
    this.setState({player: state});
    // document.getElementsByClassName('video-react-video')[0].addEventListener('contextmenu', function (e){return false;});
  }
  render() {
    const player = this.state.player;
    const isPaused = player === null ? true : this.state.player.paused;
    const height = this.props.height, width = this.props.width;
    let videoWidth = width * 0.73;
    if(!isPaused || width <= 600) {videoWidth = width;}
    // videoWidth = width;
    const videoHeight = videoWidth * 9 / 16, playListWidth = width - videoWidth, playListHeight = videoHeight + 200;
    const detail = this.state.detail ;
    let videoUrl = detail.signed_address, title = detail.title, coverUrl = detail.cover_url, viewCount = detail.view_count;
    let short_desc = detail.short_desc;
    let subscribeBanner = <></>;
    if(videoUrl === null && detail.channels.length > 0) {
      subscribeBanner = <div style={{
        position:'absolute', zIndex:2, top:0, left: 0, width:videoWidth, height: videoHeight,
        padding: 50, background: 'black', opacity: 0.75
      }}>
        <div style={{
          width: videoWidth - 100, color:'white', opacity: 1, fontSize: 20, fontWeight: 500, textAlign:'center',
          marginTop:videoHeight * 0.1
        }}>
          订阅&nbsp;&nbsp;{"[  " + detail.channels.join(",  ") + "  ]"}&nbsp;&nbsp;相关频道后进行观看<br/><br/>
          <a href={"#subscribe"} style={{white:'white', fontSize:25}}>前往订阅</a>
        </div>
      </div>
    }
    if(videoUrl === "BLANK") {
      subscribeBanner = <div style={{
        position:'absolute', zIndex:2, top:0, left: 0, width:videoWidth, height: videoHeight,
        padding: 50, background: 'black', opacity: 0.75
      }}>
        <div style={{
          width: videoWidth - 100, color:'white', opacity: 1, fontSize: 20, fontWeight: 500, textAlign:'center',
          marginTop:videoHeight * 0.3
        }}>
          视频地址无效，需联系管理员重新上传视频。
        </div>
      </div>
    }
    const infoComponent = <div style={{width:videoWidth, height: videoWidth === width ? "auto": 200, background:'#1F1F1F', padding:20, color:'white'}}>
            <div style={{width:videoWidth - 40, float:'left'}}>
              <div style={{height:30, overflow:'hidden'}}>
                <p style={{float:'left', fontSize:20, marginBottom:10, overflow:'hidden', wordBreak:'keep-all', whiteSpace:'nowrap',
                  textOverflow:'ellipsis'}} title={title}>
                  {title}
                </p>
              </div>
              <div style={{width:videoWidth / 2 + 40, height:20, position:'relative'}}>
                <img src={viewPict} style={{position:'absolute', left:0, top:4}} alt="" />
                <p style={{color:'#E0E0E0',fontFamily:'PingFangSC-Regular', fontSize:12, lineHeight:'12px', position:'absolute', left:30, top:3}}>
                  {/*观看次数&nbsp;&nbsp;&nbsp;{viewCount}&nbsp;&nbsp;&nbsp;*/}
                  内容简介&nbsp;&nbsp;<img src={require('./pict/down.png')} alt="" />
                </p>
              </div>
            </div>
            <div style={{width:videoWidth - 40, height:'auto', float:'left'}}>
              <p style={{color:'white', fontSize:14, opacity:0.8, marginTop:10}}>{short_desc}</p>
            </div>
          </div>;
    let playerElement = <Player  ref={player => {this.player = player;}}  playsInline src={videoUrl}
                       poster={coverUrl} controls={false} controlsList={'nodownload'}>
                <BigPlayButton position="center" />
                <ControlBar>
                  <PlaybackRateMenuButton rates={[1.0, 1.25, 1.5, 2.0]} />
                  <VolumeMenuButton vertical/>
                </ControlBar>
              </Player>;
                // playerElement = <video id={'shark-video'} style={{width:'100%'}} poster={coverUrl} autoPlay={'autoplay'}
                //                        controls={'controls'}
                //                        controlsList={'nodownload'} src={videoUrl}>
                //   {/*<source src={videoUrl} type={'video/mp4'}/>*/}
                // </video>;
                //   playerElement =
                //     <video id="shark-video" className="video-js vjs-default-skin" width="1000" controls data-setup='{}'>
                //       <source src="http://mirrorblender.top-ix.org/movies/sintel-1024-surround.mp4" type='video/mp4'
                //               label='SD'/>
                //       <source src="http://media.xiph.org/mango/tears_of_steel_1080p.webm" type='video/webm' label='HD'/>
                //     </video>
    try {
      document.getElementsByClassName('video-react-video')[0].
      setAttribute('controlslist', 'nodownload');
    } catch (e) {

    }
    if(videoWidth === width) {
      return (
        <>
          <div style={{width:width, background:'#1F1F1F', overflow:'hidden', margin:"50px auto", padding:0}}>
            <div style={{width:videoWidth, minHeight:videoHeight, position:'relative'}}>
              {subscribeBanner}
              <div id={'self-video-div'}  style={{width:videoWidth, height:'auto'}}>
                {playerElement}
            </div>
              {infoComponent}
            </div>
          </div>
          <Donate/>
          <div style={{width:width * 0.9, margin:"0 auto"}}>
            {detail.comment_permit ? <Comment id={detail.id}/>: <></>}
          </div>
        </>
      )
    } else {
      return(
        <>
          <div style={{width:width, background:'#1F1F1F', overflow:'hidden', margin:"50px auto", padding:0}}>
            <div style={{width:videoWidth, minHeight:videoHeight, position:'relative'}}>
              {subscribeBanner}
              <div id={'self-video-div'} style={{width:videoWidth, height:'auto'}}>
                {playerElement}
              </div>
              {infoComponent}
              <ChaptersList width={playListWidth}  playList={this.state.playList} height={playListHeight}/>
            </div>
          </div>
          <Donate/>
          <div style={{width:width * 0.9, margin:"0 auto"}}>
            {detail.comment_permit ? <Comment id={detail.id}/>: <></>}
          </div>
        </>
      )
    }
  }
}

class ChaptersList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChapterList: true,
      historyList:null
    }
  }
  render() {
    let playList = this.props.playList, historyList = this.state.historyList == null ? [] : this.state.historyList;
    const list1 = [];
    for(let i = 0; i < playList.length; i ++) {
      list1.push(
        <div style={{width:'100%', minHeight:90, paddingBottom:10, paddingTop:10, cursor:'pointer'}}
             onClick={() => window.open(
                    getFrontendUrl(
                      frontendPath.video
                        .replace('{0}', playList[i].id)
                        .replace('{1}', getQueryVariable('channel')
                      )
                   ), '_self')
                 }
        >
          <div style={{position:'relative', width:'50%', height:'auto'}}>
            <div style={{
              position:'absolute', right: 3, bottom:3, width:60, background:'rgb(0, 0, 0, 0.8)', textAlign:'center',
              fontSize:16, fontWeight: 600, color:'white'
            }}>
              {Math.floor(playList[i].duration / 60).toString() + ":" + (playList[i].duration % 60).toString()}
            </div>
            <img src={playList[i].cover_url}
               style={{width:'100%', height:'auto'}} alt=""/>
            <div style={{width:'100%', maxHeight:70, left: '100%', top: 0, paddingLeft:15, position:'absolute', overflow:'hidden'}}>
              <p style={{fontSize:12, color:'white', marginBottom:27.5}} title={playList[i].title}>
                {playList[i].title}
              </p>
            </div>
          </div>
        </div>
      );
    }
    const outer = '#1F1F1F', inner = '#2F2F2F';//'#1F203C'
    return (
      <div style={{width:this.props.width, height:this.props.height, background:outer, position:'absolute', left:'100%', top:0, padding:10}}>
        {/*333353*/}
        <div style={{float:'left', width:(this.props.width - 20) / 2, height:35, textAlign:'center', paddingTop:7,
          background:this.state.isChapterList ? inner: outer}}>
          <p style={{color:'white', cursor:'pointer', opacity:this.state.isChapterList ? 1.0:0.8}} onClick={(e) => console.log(e)}>
            选集
          </p>
        </div>
        <div style={{float:'left', width:(this.props.width - 20) / 2, height:35, textAlign:'center', paddingTop:7,
          background: !this.state.isChapterList ? inner: outer}}>
          <p style={{color:'white', cursor:'pointer', opacity: !this.state.isChapterList ? 1.0:0.8}} onClick={(e) => console.log(e)}>
            {/*历史观看*/}
          </p>
        </div>
        <div style={{width:this.props.width - 20, height:this.props.height - 55,
          overflowX:'hidden', overflowY:'scroll',
          background:inner, float:'left', padding:10}} className={'chapter-list'}>
          {list1}
        </div>
      </div>
    )
  }
}

export default PlayerComponent;
