import React from 'react';
import { AppstoreOutlined, FileTextOutlined, UserOutlined, TeamOutlined, DeploymentUnitOutlined  } from '@ant-design/icons';
import './css/header.css';
import {backendPath, frontendPath, frontendUrlBase, getBackendUrl, getFrontendUrl} from "../config/url";
import {clearCookie, getEmail, isLoggedIn} from "../config/cookie";
import {checkRoles} from "../config/roles";
import {Role} from "../config/roles";
import axios from "axios";
import logo from '../img/logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../css/fancybox/jquery.fancybox.css";
import "../css/flexslider.css";
import "../css/style.css";
import jwt_decode from "jwt-decode";
import {RegisterModel} from "./headerRegisterModel";
import {LoginModel} from "./headerLoginModel";
import {ResetModel} from "./headerResetModel";
const registerModel = 'registerModel', loginModel = 'loginModel';


class HeaderComponent extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      channels: [],
      showModel: null
    };
    if(!window.location.href.startsWith(frontendUrlBase)) {
      window.open(getFrontendUrl(frontendPath.home), "_self");
      return ;
    }
    const current = this;
    axios.get(
      getBackendUrl(backendPath.channel.list.path + "?header=true")
    ).then(function (response) {
      current.setState({channels: response.data.result})
    })
  }

  render() {
    const channelSection = [], current = this;
    for(let channel of this.state.channels) {
      channelSection.push(
        <li>
          <a href={getFrontendUrl(frontendPath.channel.replace('{0}', channel.id))}>
            {channel.title}
          </a>
        </li>)
    }
    let userSection = [
      <li onClick={() => current.setState({showModel: loginModel})}><a href="#" style={{padding:"25px 5px 25px 30px"}}>登录</a></li>,
      <li onClick={() => current.setState({showModel: registerModel})}><a href="#" style={{padding:"25px 20px 25px 5px"}}>注册</a></li>
    ];
    if(isLoggedIn()) {
      userSection = [
        <li className="dropdown" >
          <a href="#" data-toggle="dropdown" className="dropdown-toggle">
            {getEmail()}&nbsp;&nbsp;<UserOutlined/>
          </a>
          <ul className="dropdown-menu" id={'profile-center'}
              style={{width: document.getElementById('email-account') ? document.getElementById('email-account').offsetWidth: 200}}>
            <li><a href="#" style={{textAlign:'center'}} onClick={function () {
              window.open(getFrontendUrl(frontendPath.profile), "_self")
            }}>个人中心</a></li>
            <li><a href="#" style={{textAlign:'center'}} onClick={function () {
              clearCookie();
              window.location.reload();
            }}>退出登录</a></li>
          </ul>
        </li>
      ]
    }
    return (
     <>
       <header>
        <div className="navbar navbar-default navbar-static-top">
          <div className="container">
            <div className="navbar-header">
              <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <a className="navbar-brand" href={getFrontendUrl(frontendPath.home)}>
                <h2 style={{marginTop: 10}}><span style={{color:'green'}}>Shark</span>Studio</h2>
                </a>
            </div>
            <div className="navbar-collapse collapse ">
              <ul className="nav navbar-nav">
                <li className="active"><a href={getFrontendUrl(frontendPath.home)}>主页</a></li>
                <li><a href={getFrontendUrl(frontendPath.community)}>社区</a></li>
                {channelSection}
                {userSection}
              </ul>
            </div>
          </div>
        </div>
      </header>
       <RegisterModel parent={this}/>
       <LoginModel parent={this}/>
       <ResetModel parent={this}/>
     </>
    );
  }
}



export default HeaderComponent;
