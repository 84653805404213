import React from 'react';
import './scroll.css';
import {backendPath, getBackendUrl} from "../../config/url";
import {dateToString} from "../../config/transform";
import {Table} from 'antd'
import emptyCourseListPict from './pict/emptyCourseList.png'


export class SubscribeOrderList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      orderList: []
    };
    const cur = this;
    let httpRequest = new XMLHttpRequest();
    httpRequest.open(backendPath.order.list.method, getBackendUrl(backendPath.order.list.path), true);
    httpRequest.withCredentials = true;
    httpRequest.send();
    httpRequest.onreadystatechange = function () {
      if (httpRequest.readyState === 4) {
        if (httpRequest.status === 200) {
          cur.setState({orderList: JSON.parse(httpRequest.responseText)['result']});
        }
      }
    };
  }

  render() {
    const width = this.props.width;

    let orderList = this.state.orderList, list = [];

    for(let i = 0; i < orderList.length; i ++) {
      list.push({
        orderId: orderList[i].order_id,
        type: orderList[i].type,
        addTime: dateToString(new Date(orderList[i].add_time)),
        status: orderList[i].status,
        notCanceled: orderList[i].not_canceled ? "是" : "已取消"
      }
      )
    }

    if(list.length === 0) {
      list = <div style={{textAlign:'center', paddingTop: 50}}>
        <img src={emptyCourseListPict} style={{width:150, height:100}} alt=""/><br/>
        <p style={{marginTop:20, fontSize:20, opacity:0.6}}>还未订阅过任何频道</p>
      </div>
    }
    const columns = [
      // {
      //   title: '订阅号',
      //   dataIndex: 'orderId',
      //   key: 'orderId',
      // },
      {
        title: '订阅类型',
        dataIndex: 'type',
        key: 'type',
      },{
        title: '订阅时间',
        dataIndex: 'addTime',
        key: 'addTime',
      },{
        title: '订阅状态',
        dataIndex: 'status',
        key: 'status',
      },{
        title: '自动付款',
        dataIndex: 'notCanceled',
        key: 'notCanceled',
      },
    ]
    return (
      <div style={{width: "100%", textAlign:'center'}}>
        <div style={{marginBottom:10}}>
          <p style={{color:'black', textAlign:'left', fontSize:20, marginBottom:30, fontWeight:600}}>订阅历史</p>
        </div>
        {orderList.length > 0 ?
          <Table columns={columns} dataSource={list} />:
          list
          }
      </div>
    );
  }
}
