import React from 'react';

import Icon from 'antd/lib/icon'
import 'antd/lib/icon/style'
import Tooltip from 'antd/lib/tooltip'
import 'antd/lib/tooltip/style'
import './profile.css';
import {PwdResetModal} from "./pwdResetModal";
import {InfoResetModal} from "./infoResetModal";
import avatar from './pict/avatar.jpg'
import {backendPath, getBackendUrl, s3UrlBase} from "../../config/url";


export class ProfileInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: null,
      avatar: avatar,
      nickname: '美股大鲨鱼用户',
      company: "",
      wechat: ""
    };
    const cur = this, xmlhttp=new XMLHttpRequest();
    xmlhttp.open(backendPath.user.getInfo.method, getBackendUrl(backendPath.user.getInfo.path), true);
    xmlhttp.withCredentials = true;
    xmlhttp.send();

    xmlhttp.onreadystatechange = function(){
      if(xmlhttp.readyState === 4) {
        if(xmlhttp.status === 200 ) {
          const response = JSON.parse(xmlhttp.responseText);
          if(response['avatar'] != null) {
            cur.setState({avatar: s3UrlBase + response['avatar']});
          }
           cur.setState({
              nickname: response['nick_name'],
             company: response['company'],
             wechat: response['wechat'],
           });
        }
      }
    };
  }
  handleClick(show) {
    this.setState({showModal: show});
  }
  render() {
    // backgroundImage:`url(${require('./pict/button.png')})`, backgroundSize:'100% 100%', cursor:'pointer'
    return (
      <div style={{width:'100%', height:this.props.height, textAlign:'center', margin:'0 auto', fontSize:14}}>
        <div style={{width:100, height:100, borderRadius:100, overflow:'hidden', margin: '0 auto'}}>
          <img src={this.state.avatar} style={{width:100, height:100}} alt="" />
        </div>
        <p style={{height:30, marginTop:25, marginBottom:5, fontSize:30}}>Hi&nbsp;&nbsp;{this.state.nickname}</p>
        <span className={'self-menu-item'}
           onClick={this.handleClick.bind(this, 'pwd')}>
          修改密码
        </span>
        &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
        <span className={'self-menu-item'}
           onClick={this.handleClick.bind(this, 'info')}>
          修改资料
        </span>
        <PwdResetModal modalWidth={600} parent={this}/>
        <InfoResetModal modalWidth={600} parent={this}/>
      </div>
    );
  }
}
