import React from 'react';
import HeaderComponent from "../../components/header";
import axios from "axios";
import {backendPath, frontendPath, getBackendUrl, getFrontendUrl, getQueryVariable} from "../../config/url";
import {message} from "antd";
import {headerHeight} from "../../config";
import FooterComponent from "../../components/footer";
import banner1 from "../../img/slides/1.jpg";
import Subscribe from "../home/subscribe";
import {isLoggedIn} from "../../config/cookie";
import {ProfileInfo} from "./profileInfo";
import {SubscribeOrderList} from "./subscribeOrderList";
import {LiveList} from "./liveList";
import NewVideos from "./newVideos";
import Donate from "../../components/donate";



class ProfilePage extends React.Component {
  constructor(props) {
    super(props);
    if(!isLoggedIn()) {
      window.open(getFrontendUrl(frontendPath.home), "_self");
    }
    if(getQueryVariable('msg') === 'pay-failed') {
      message.warn("支付失败。")
    }
  }

  render() {

    return(
      <>
        <HeaderComponent/>
        {/*<section id="inner-headline">*/}
          {/*<div className="container">*/}
            {/*<div className="row">*/}
              {/*<div className="col-lg-12">*/}
                {/*<h2 className="pageTitle">Your Profile</h2>*/}
              {/*</div>*/}
            {/*</div>*/}
          {/*</div>*/}
        {/*</section>*/}
        <section className={"section-padding gray-bg"} style={{padding:40, paddingBottom:0}}>
          <div className="container">
            <ProfileInfo width={200} height={200}/>
          </div>
        </section>
        <section className={"section-padding gray-bg"} style={{padding:50}}>
          <div className="container">
            <LiveList width={1100}/>
          </div>
        </section>
        <Donate title={"直播分享"}/>
        <section className={"section-padding gray-bg"}>
          <div className="container">
            <SubscribeOrderList width={1100}/>
          </div>
        </section>
        <NewVideos/>
        <FooterComponent/>
      </>
    )
  }
}
export default ProfilePage;
