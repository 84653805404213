import React from 'react';
import './scroll.css';
import '../../css/loading.css'
import {Table} from 'antd'
import {backendPath, frontendPath, getBackendUrl, getFrontendUrl} from "../../config/url";
import {dateToString} from "../../config/transform";
import emptyCourseListPict from './pict/emptyCourseList.png'


export class LiveList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      liveList: null
    };
    const cur = this;
    let httpRequest = new XMLHttpRequest();
    httpRequest.open(backendPath.live.list.method, getBackendUrl(backendPath.live.list.path), true);
    httpRequest.withCredentials = true;
    httpRequest.send();
    httpRequest.onreadystatechange = function () {
      if (httpRequest.readyState === 4) {
        if (httpRequest.status === 200) {
          cur.setState({liveList: JSON.parse(httpRequest.responseText)['webinars']});
          // cur.setState({liveList: []});
        }
      }
    };
  }

  render() {
    const width = this.props.width;

    let liveList = this.state.liveList, list = [];

    if(liveList === null) {
      list = <div className={'loading'} style={{marginTop:'5em'}}></div>
    } else {
      for(let i = 0; i < liveList.length; i ++) {
        list.push({
          startTime: dateToString(new Date(liveList[i].start_time)),
          topic: liveList[i].topic,
          joinUrl: <a href={liveList[i].join_url ? liveList[i].join_url: getFrontendUrl(frontendPath.home) + "#subscribe"} target={'_blank'}>
                {liveList[i].join_url ? "点击进入" : "——"}
              </a>
        }
        )
      }
    }

    if(list.length === 0) {
      list = <div style={{textAlign:'center', paddingTop: 0}}>
        <p style={{marginTop:20, fontSize:20, opacity:0.6}}>暂无直播安排</p>
      </div>
    }

    const columns = [
      {
        title: '当地时间',
        dataIndex: 'startTime',
        key: 'startTime',
      },
      {
        title: '主题',
        dataIndex: 'topic',
        key: 'topic',
      },
          {
        title: '直播地址',
        dataIndex: 'joinUrl',
        key: 'joinUrl',
      },
    ]

    return (
      <div style={{width: "100%", textAlign:'center'}}>
        <div style={{marginBottom:10}}>
          <p style={{color:'black', textAlign:'left', fontSize:20, marginBottom:30, fontWeight:600}}>直播安排</p>
        </div>
        {
          liveList !== null && liveList.length > 0 ?
            <Table columns={columns} dataSource={list} />:
            list
        }
      </div>
    );
  }
}
