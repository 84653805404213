import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/fancybox/jquery.fancybox.css";
import "../../css/flexslider.css";
// import "../../css/style.css";
import {message} from "antd";
import axios from "axios";
import {backendPath, getBackendUrl} from "../../config/url";
import {isLoggedIn, setCookie} from "../../config/cookie";
import { LoadingOutlined } from "@ant-design/icons"
import {LoginModel} from "../../components/headerLoginModel";
import Modal from 'antd/lib/modal'
import 'antd/lib/modal/style'
import banner from '../../img/slides/small.png'
import "../../css/style.css";


class Subscribe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscription: [],
      subscriptionCreating: "",
      accept: false,
      read: false,
      windowWidth: document.body.clientWidth
    };
    const current = this;
    axios.get(
      getBackendUrl(backendPath.subscription.list.path)
    ).then(function (response) {
      current.setState({subscription: response.data})
    });
    window.onresize = (e) => {current.setState({windowWidth: document.body.clientWidth})};
  }
  handleSubscribe(subscriptionID) {
    if(!isLoggedIn()) {
      message.warn("请先登录账号。");
      return ;
    }
    if(!this.state.accept) {
      message.warn("请阅读并同意下方会员协议");
      return ;
    }
    let httpRequest = new XMLHttpRequest(), cur = this;
    httpRequest.open(
      backendPath.order.subscribe.method,
      getBackendUrl(backendPath.order.subscribe.path), true);
    httpRequest.withCredentials = true;
    let fd = new FormData();
    fd.append("subscription", subscriptionID);
    fd.append("channel", "2");
    httpRequest.send(fd);
    this.setState({subscriptionCreating: subscriptionID});
    httpRequest.onreadystatechange = function () {
        if (httpRequest.readyState === 4) {
          const obj = JSON.parse(httpRequest.responseText);
          if (httpRequest.status === 200) {
            window.open(obj['url'], "_self")
          } else if (httpRequest.status === 400) {
            message.warning(obj['msg']);
            cur.setState({subscriptionCreating: ""});
          } else {
            message.warning("创建订单失败");
            cur.setState({subscriptionCreating: ""});
          }
        }
    };
  }
  render() {
    const subscription = this.state.subscription;
    let subscriptionSection = [], cols = 2;
    if(subscription.length > 2) {
      if(subscription.length > 4) cols = 4;
      else cols = subscription.length;
    }
    const className = 'col-sm-' + Number(12 / cols).toString();
    for(let i = 0; i < subscription.length; i += cols) {
      const line = [];
      for(let index = 0; index < cols && i + index < subscription.length; index ++) {
        const sub = subscription[i + index];
        if(sub.price <= 0) continue ;
        const serviceSection = [];
        for(let ser of sub.desc.split("\n")) {
          serviceSection.push(<li><i className="icon-ok"></i>{ser}</li>)
        }
        line.push(
          <div className={className}>
              <div className="pricing-box-item">
                <div className="pricing-heading" style={{padding:"15px 0"}}>
                  <h3><strong>{sub.title}</strong></h3>
                </div>
                <div className="pricing-terms">
                  <h6>&#36;{sub.price}&nbsp;/&nbsp;月</h6>
                </div>
                <div className="pricing-container">
                  <ul>
                    {serviceSection}
                  </ul>
                </div>
                <div className="pricing-action">
                  <a className="btn btn-medium" onClick={this.handleSubscribe.bind(this, sub.id)}>
                    {this.state.subscriptionCreating === sub.id ? <><LoadingOutlined/>&nbsp;&nbsp;订单创建中</> : "订阅"}
                  </a><br/><br/>
                  <input type="radio" style={{cursor:'pointer'}} onClick={() => this.setState({accept: true, read: false})}/>
                &nbsp;&nbsp;<b>我已同意如下会员协议</b>
                &nbsp;<span style={{color:'#009cde', cursor:'pointer'}} onClick={() => this.setState({read: true})}>协议内容</span>
                </div>
              </div>
            </div>
        )
      }
      subscriptionSection.push(
        <div className="row">
          <div className="col-lg-12">
          </div>
          {line}
        </div>
      );
    }
    // let style = {background:'#fedd14'};
    //             if(this.state.windowWidth > 979) {
    //               style.padding = '50px 15%'
    //             } else if(this.state.windowWidth > 767) {
    //               style.padding = '40px 12%'
    //             } else if(this.state.windowWidth > 480) {
    //               style.padding = '40px 10%'
    //             } else {
    //               style.padding = '40px 2%'
    //             }
    let style = {width:'100%', height:'auto'}
    return(
    <>
      {/*<section id="inner-headline">*/}
        {/*<div className="container">*/}

          {/*<div className="row">*/}
            {/*<div className="col-lg-12">*/}
              {/*<h2 className="pageTitle"></h2>*/}
            {/*</div>*/}
          {/*</div>*/}
        {/*</div>*/}
      {/*</section>*/}
      <div className={'subscribe-banner'} style={style}>
        <img style={{width:'100%'}} src={banner}/>
      </div>
      <section id="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="about-logo">
                <h2><span className="coloured">订&nbsp;阅&nbsp;计&nbsp;价</span></h2>
                <p>DISCLAIMER: I am not a financial adviser nor a CPA. These videos are for recording my mind only. Investing of any kind involves risk. your investments are solely your responsibility. I am sharing my opinion with no guarantee of gains or losses on investments.</p>
<p>免责声明:我不是财务顾问，也不是注册会计师。这些视频仅用于记录我的想法。任何形式的投资都涉及⻛险。 您的投资完全由您自己负责。 我分享我的观点，不保证投资的收益或损失。</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          {subscriptionSection}
        </div>
        <div className="container" style={{opacity:0}}>
          <div className="row">
            <div className="col-md-12">
              <div className="about-logo">
                <input type="radio" style={{cursor:'pointer'}} onClick={() => this.setState({accept: true, read: false})}/>
                &nbsp;&nbsp;<b>我已同意如下会员协议</b>
                &nbsp;<span style={{color:'#009cde', cursor:'pointer'}} onClick={() => this.setState({read: true})}>协议内容</span>
              </div>
            </div>
          </div>
        </div>
        <Modal visible={this.state.read} footer={null} width={900}
               onCancel={() => this.setState({read: false})}>
          <div className="custom-html-style"><h3>会员协议</h3>
            <h4>1.常规条款</h4>
            <p>1.1 <a href="http://xn--www-c88dv70fmr3c.longwayinvesting.com">为获得www.longwayinvesting.com</a>(以下简称Longway)提供基于互联网的相关服务，服务使用人(以下称“用户”)必须同意本协议的全部条款并按照页面上的提示完成注册程序。如果用户在注册程序过程中勾选“
              我已认真阅读并同意遵守以上用户协议”按钮即表示用户完全接受本协议项下的全部条款，您在使用Longway提供的各项服务之前，应仔细阅读本服务协议。如您不同意本服务协议及/或随时对其的修改，您可以主动取消Longway提供的服务；您一旦使用Longway提供的服务，即视为您已了解并完全同意本服务协议各项内容，包括Longway对服务协议随时所做的任何修改，并成为Longway用户。
            </p>
            <p>1.2 Longway帐号（即Longway用户ID）的所有权归Longway Capital LLC
              所有，用户完成注册申请手续后，获得Longway帐号的使用权。用户应提供及时、详尽及准确的个人资料，并不断更新注册资料，符合及时、详尽准确的要求。所有原始键入的资料将引用为注册资料。如果因注册信息不真实而引起的问题，并对问题发生所带来的后果，Longway不负任何责任。用户应当对以其用户帐号进行的所有活动和事件负法律责任。</p>
            <p>1.3 内容不适合或针对 19 岁以下的人。您必须年满 19 岁或至少达到您居住或使用内容所在司法管辖区的法定成年年龄。
              除非不需要，要访问和使用内容，您必须通过提供有效的电子邮件地址进行订阅，或通过提供所需的信息来注册订阅帐户（“帐户”）。您声明并保证提供给我们的所有信息都是真实、准确、完整和最新的。
              您承认我们将使用提供给我们的电子邮件地址作为主要通信方式，并同意我们根据公司的隐私政策向您发送任何营销、信息或内容到该电子邮件地址。
              禁止用户将其帐号、密码转让或出借予他人使用，如用户发现其帐号遭他人非法使用，应立即通知Longway。因黑客行为或用户的保管疏忽导致帐号、密码遭他人非法使用，Longway不承担任何责任。</p>
            <p>1.4 会员注册用户名时请勿使用敏感词汇，（包括但不限于：不雅词汇、涉及政治、法律等词汇以及含有 Longway、版主、管理员、网站等词汇）。Longway有权删除此类账户，无需通知第三方。</p>
            <h4>2. 服务内容</h4>
            <p>2.1 <a href="http://Longwayinvesting.com">Longwayinvesting.com</a> 由 Longway Capital LLC 拥有和运营的网站。 （本协议中称
              “Longway”、“本人”，“本公司”，“公司”、或“我们”）。 不是获得许可的投资顾问、经纪交易商或金融分析师，也不属于任何财务建议或研究小组。我们只分享自己的投资经验。</p>
            <p><a href="http://longwayinvesting.com">longwayinvesting.com</a>、其公司页面、附属电子邮件、聊天室或讨论群组
              （<a
                href="http://xn--longwayinvesting-d412a.com">在longwayinvesting.com</a> 或其他方式）、视频或网络研讨会上提供的任何内容，包括但不限于通过
              YouTube 频道,无论是书面、口头还是电子传输（统称为“内容”），均为作者的个人认知，不应被视为个人财务建议. Longway提供的所有内容均是个人投资经验的分享。</p>
            <p>2.2 没有财务建议</p>
            <p>所有内容中包含的信息无意提供理财、投资、财务或税务建议，单纯分享，分析，纯属个人意见和看法。在这方面不应依赖内容进行投资。在对内容中包含的任何信息采取行动之前，请根据您的特定情况咨询您的理财师、会计师、金融专业人士或其他顾问。您同意自行承担观看、访问和使用内容中的信息的任何风险。</p>
            <p>所有内容并非旨在宣传任何特定产品或投资，公司均不以任何方式推荐或认可其中可能讨论的任何公司、产品、投资或机会。</p>
            <p>本人只是分享自己的投资经验，方法，策略等内容。不能保证您会从使用内容中包含的方法、策略、流程、技术和想法中赚钱或从中受益。内容中的特定资产、证券、股票、期权或其他金融工具和交易的示例仅用于说明和讲解目的，并不代表进行的特定交易或交易。</p>
            <p>内容可能包含或基于《证券法》。您可以通过这些陈述与历史或当前事实不严格相关这一事实来识别这些陈述。它们通常包括诸如“预期”、“估计”、“预计”、“项目”、“打算”、“计划”、“相信”等词，以及与潜在收益或财务状况描述相关的其他类似含义的术语，表现。内容中包含的任何和所有前瞻性信息仅用于表达我们对盈利潜力的看法，本公司不保证您将通过使用前瞻性信息取得任何成果。</p>
            <p>投资证券具有投机性和风险性，可能并不适合所有投资者。您应该仔细考虑您的投资目标、经验水平和风险偏好。过去的表现并不代表或保证未来的结果。购买、出售、持有或交易证券和其他投资的决定涉及风险，只能根据合格投资顾问的建议做出。任何证券交易或其他投资都是投机性的，并涉及重大损失的风险。除非您准备好损失全部投资，否则您不应投资于证券或其他投资。请您根据您目前的财务状况和承担财务风险和成本的能力，慎重考虑该交易是否适合您。请就您的具体情况咨询您的投资顾问，律师、会计师、金融专业人士或其他顾问。</p>
            <p>2.3 内容的使用</p>
            <p>您只能将内容用于您的个人、非商业目的，并受这些条款中规定的资格和声明的约束。</p>
            <p>您同意不会以任何方式将内容用于：</p>
            <p>( a ) 从事任何盈利或非法活动；</p>
            <p>( b ) 跟踪、骚扰、威胁或伤害他人；</p>
            <p>( c ) 进行任何欺诈活动；</p>
            <p>( d ) 建议、引诱、转移或雇用任何用户、承包商或员工从事公司的任何竞争业务；</p>
            <p>( e ) 未经我们事先明确书面同意您作出的任何陈述和意见；</p>
            <p>( f ) 修改、改编、再许可、翻译、出售、保留工程、反编译或反汇编内容的任何部分。</p>
            <p>您同意在查看、访问或使用内容的过程中不会：</p>
            <p>( a ) 限制或禁止任何其他访问者或用户使用内容；</p>
            <p>( b ) 煽动仇恨或宣传侵犯任何访客和/或用户权利的材料；</p>
            <p>( c ) 向其他用户发送未经请求或未经授权的广告或内容，包括但不限于垃圾邮件、连锁信、弹出窗口或其他商业性质的材料；</p>
            <p>( d ) 在内容上征集或做广告，或添加指向您自己网站的滥用或非相关链接；</p>
            <p>( e ) 未经其他用户或访问者的明确书面同意，收集、存储或发布有关其他用户或访问者的个人信息或数据；</p>
            <p>( f ) 共享、传播或分发通过您的订阅和/或订阅产品提供的任何内容、产品或材料；</p>
            <p>( g ) 未经我们事先书面授权“构架”或“镜像”内容的任何部分；</p>
            <p>( h )
              使用任何机器人、蜘蛛、站点搜索/检索应用程序或其他手动或自动设备、过程或代码来下载、检索、索引、“数据挖掘”、“抓取”、“收获”或以任何方式复制或绕过内容或其中包含的任何信息的导航结构或呈现；</p>
            <p>( i ) 冒充任何人或实体，或以其他方式歪曲您与任何人或实体的从属关系，或混淆其他用户或从事不适当、不道德或误导性的行为；</p>
            <p>( j ) 侵犯任何一方的知识产权；</p>
            <p>( k ) 干扰或破坏内容的完整性或性能；</p>
            <p>( l ) 试图未经授权访问任何系统、网络或数据；</p>
            <p>( m ) 包含或使用亵渎、粗俗、淫秽或色情内容；</p>
            <p>( n ) 发送垃圾邮件或传输任何恶意软件或病毒或其他有害</p>
            <h4>3.第三方平台和内容</h4>
            <p>内容中包含的某些链接连接到第三方网站和/或由此类第三方维护的材料（“第三方材料”）。 Longway未验证此类第三方网站或材料的内容，也不认可、保证、宣传或推荐第三方提供的任何服务或产品。
              内容中提供的任何第三方材料均不是对该第三方或其可能提供的任何服务或产品的认可，也不是我们与此类第三方的隶属关系的授权或陈述。
              您承认我们不控制或监控任何第三方材料。我们无法保证此类第三方提供的任何材料和/或信息的准确性、完整性或时效性。您访问此类第三方网站和内容的风险由您自行承担，并且可能会受到有关使用或披露个人身份信息的不同条款和条件的约束。</p>
            <h4>4.用户提交</h4>
            <p>内容可能包括用户提交的某些信息，包括但不限于评论、文章、链接和对话（“用户提交”）的形式。我们不认可或支持任何用户表达的任何意见和/或评论。
              您对您和您的帐户进行的所有用户提交和活动负责，无论此类活动是由您还是由另一方进行。</p>
            <p>我们保留以下权利，恕不另行通知：</p>
            <p>( a ) 如果我们自行决定确定为非法、未经授权、攻击性、威胁性、诽谤、诽谤、色情、淫秽或其他令人反感或违反任何一方的行为，则随时监控、审查、编辑、移动或删除任何用户提交的内容知识产权或这些条款；</p>
            <p>( b ) 如果适用，将您从发布、上传或传播用户提交的任何讨论组或聊天室中删除，包括但不限于我们的网站。</p>
            <p>通过发布或上传任何此类用户提交的内容，您允许用户查看此类发布和上传内容，并授予 Longway
              不可撤销的、永久的、全球性的、非排他性的、免版税的许可（具有再许可的权利）以使用、复制、以任何在所有媒体或分发方法（现在已知或以后开发）复制、处理、改编、修改、出版、传输、展示和分发。您同意，对于您在用户提交中可能拥有的任何专有权利的任何和所有涉嫌或实际侵权或盗用，我们概不负责。</p>
            <p>我们可以自行决定授予特定员工、代理或承包商审核任何和所有用户提交的权限。由于用户提交的数量，我们可能不会与用户一起审查审核决定。此外，个别主持人可能会针对他们主持的聊天室采用他们自己的准则。鼓励用户始终遵循版主指南。如果此类版主指南与条款或公司的隐私政策不一致，则以条款或公司的隐私为准。</p>
            <p>4.1 隐私政策</p>
            <p>您同意我们根据我们的隐私政策收集、使用和披露与您使用内容相关的信息。您不得规避或试图规避我们可能采取的任何措施来保护您的帐户或信息，或我们自己、我们的关联公司或其他用户的帐户或信息的隐私或机密性，包括加密措施或使用多重-因素身份验证。</p>
            <p>4.2 信息安全</p>
            <p>根据隐私政策，您同意任何旨在保护、或维护信息完整性或机密性的措施，包括通过互联网传输的方法或电子存储方法，都不能保证您信息的安全。我们对您的信息的安全性和安全性不作任何保证。</p>
            <h4>5. 知识产权</h4>
            <p>您承认您仅获得使用和访问内容的有限权利。无论使用“购买”、“销售”或以下类似术语，均不向您转让所有权。
              内容、材料、信息、图形、程序和所有相关和基础技术、文档、工作产品、工具、设计、方法、流程、应用程序、技术、想法、专有技术和所有衍生作品、修改、改进、更新、前述内容的添加或补充以及所有评论、问题、建议、反馈和任何版权、商标、服务标志和其他知识产权（统称为“公司资料”）均为公司的财产，如果适用，公司附属公司和任何第三方许可方或供应商，除非另有特别说明。公司、公司关联公司和第三方许可方保留对此类材料的所有权利、所有权和利益。您同意公司拥有并将保留公司材料的所有权利、所有权和利益。由于您使用公司材料而产生的所有商誉将完全符合公司的利益。</p>
            <p>您同意您不得，也不会允许或协助任何第三方：</p>
            <p>( a ) 反向工程、反编译或反汇编任何公司材料和任何及所有相关内容，或以其他方式寻求获取任何公司材料的源代码或非公开 API；</p>
            <p>( b ) 将公司材料与非我们提供的硬件、软件或其他技术或材料结合或集成；</p>
            <p>( c ) 从任何公司材料修改或创建任何衍生作品；</p>
            <p>( d ) 以任何方式复制、复制、再版、上传、张贴、传输、共享或分发公司材料；或者</p>
            <p>( e ) 删除或掩盖任何公司材料中包含的任何版权、所有权或其他通知。</p>
            <p>公司有权自行决定提交、起诉和维护与公司材料相关的所有申请、注册、商标、版权和专利。</p>
            <h4>6.支付</h4>
            <p>6.1 您同意支付与您选择购买的订阅产品（“订阅产品”）相关的所有费用。在整个订阅期间，您将被立即收取与当时订阅费相等的金额，并且您对所有经常性费用承担责任，直到您取消订阅。</p>
            <p>所有费用均不包括适用税费，您有责任支付与购买订阅产品相关的任何税费。
              购买任何订阅产品都需要有效的信用卡。您授权我们向您在我们这里存档的任何信用卡或任何替换卡收取适用的经常性订阅费。</p>
            <p>您声明并保证您是信用卡的合法所有者或您被授权提供和使用信用卡。
              您同意在任何信用卡信息发生变化或您的信用卡过期或被拒绝时立即通知我们，并向我们提供有效的替换信用卡。</p>
            <p>您将在 30 天内通知我们任何计费问题或差异。如果您未在 30 天内提请我们注意，则您放弃对此类问题或差异提出异议的权利。</p>
            <p>我们可能随时更改我们的任何费用，无论是否通知您，或制定新的收费或费用。费用变更和新收费不适用于任何现有订阅会员，而仅适用于费用变更生效日期后的新会员和订阅续订。费用变更后对现有订阅产品的任何续订均应支付新的费用或费用。</p>
            <p>6.2 续订</p>
            <p>订阅产品将设置为在期限届满时自动续订。除非您在当前计费周期内取消订阅，否则您的帐户将自动续订相同期限。在续订时，您将被收取当时的订阅费用。</p>
            <p>6.3 暂停内容</p>
            <p>如果出现以下情况，我们保留暂停您的帐户、订阅产品和/或访问内容和任何相关支持的权利，且不对您承担任何责任</p>
            <p>(a) 您未在收到此类付款提醒后 5 天内支付任何未付金额；或者</p>
            <p>(b) 您违反了您在这些条款下的任何义务。</p>
            <p>在因违规而暂停您的访问之前，我们将尽合理努力向您提供通知和合理的补救机会，除非我们自行决定合理确定此类违规可能对其他用户造成伤害或威胁安全或完整性公司、公司关联公司、公司材料、内容或任何第三方的信息，在这种情况下可能会立即暂停。</p>
            <p>在任何暂停期间：</p>
            <p>( a ) 您在本协议项下的权利、义务和责任将继续完全有效；</p>
            <p>( b ) 您应付的所有费用和收费仍将到期应付；</p>
            <p>( c ) 任何暂停期间均不提供退款、信用或补偿。</p>
            <p>我们的暂停权利是我们任何其他权利或补救措施的补充，包括但不限于任何终止</p>
            <h4>7. 免责声明</h4>
            <p>7.1 用户明确同意其使用Longway网络服务所存在的风险将完全由其自己承担；因其使用Longway网络服务而产生的一切后果也由其自己承担，Longway对用户不承担任何责任。</p>
            <p>7.2 Longway不担保网络服务一定能满足用户的要求，也不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。</p>
            <p>7.3 Longway不保证为向用户提供便利而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由Longway实际控制的任何网页上的内容，Longway不承担任何责任。</p>
            <p>7.4对于因不可抗力或Longway不能控制的原因造成的网络服务中断或其它缺陷，Longway不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。</p>
            <p>7.5
              除条款中规定的有限保证外，内容、公司材料以及通过内容或公司材料或信息获得或作为其一部分获得的任何和所有相关信息、产品、工具均按“原样”提供在适用法律允许的最大范围内，不作任何明示或暗示的陈述或保证。我们在法律允许的最大范围内明确拒绝任何形式的保证，无论是明示的、暗示的或法定的，包括但不限于所有权和非侵权保证、适销性和特定用途适用性的暗示保证，以及与内容和公司材料中包含的任何信息的充分性、准确性、可靠性、安全性、有用性或完整性有关的所有保证。本公司不对任何内容的任何错误或遗漏负责。</p>
            <h4>8. 放弃索赔</h4>
            <p>我们明确拒绝和披露如下，并且您在此确认您理解、承认并同意以下内容，并明确拒绝、免除和放弃任何责任、索赔、损害赔偿、损失、内容如下：</p>
            <p>我们不是获得许可的投资、财务、税务或法律顾问，也不是经纪自营商，也不声称以任何形式提供个性化的投资、财务、税务或法律建议。我们不建议购买或出售特定证券，也不承诺或保证任何特定投资结果。</p>
            <p>我们不代表或保证内容和公司材料的完整性、真实性、准确性、有用性或可靠性。
              您了解并承认交易证券涉及高度风险，尤其是交易期货、期权和低价股。您承认并同意，您而非我们对自己的投资研究、尽职调查和决策负全部责任。您承认在进行任何投资之前，您有责任在必要时寻求合格证券专业人士和/或税务或法律顾问的建议，并在投资前调查并充分了解任何和所有风险。我们强烈建议您咨询您的持牌财务、投资、法律或税务顾问，以确定任何投资的适合性。</p>
            <p>我们发布的任何个人交易者或交易系统的过去结果并不表示该交易者或系统的未来回报，也不表示您可能实现的未来回报。内容的方法、技术、信息、内容、指标、策略、专栏、文章和所有其他功能仅供参考和分享目的，不应被解释为投资建议。您在进行任何投资时不应依赖我们提供的任何信息。您对自己的交易决定负全部责任，信息中的任何内容都不是或应该是对任何特定结果的承诺或保证。</p>
            <p>我们和我们的公司对您的交易和投资结果不承担任何责任或义务，并且您同意让我们和我们的公司免受任何此类结果或损失的伤害。</p>
            <h4>9.责任限制</h4>
            <p>使用内容的风险由您自行承担，您同意让
              Longway、其所有者、董事、员工、附属公司、承包商和代理商免受伤害，并完全免除他们因您使用或依赖内容而可能招致的伤害。所呈现的任何内容或任何意见陈述或表达，或本文中的任何其他事项，不直接或间接构成
              Longway 的代表，也不构成对任何证券的购买或出售的征求。</p>
            <p>公司及其所有者、子公司、公司附属公司、合资企业及其各自的董事、高管、股东、员工、代理人和承包商均不承担任何责任、特定责任、附带责任，任何形式的惩罚性损害或损失，无论是由于合同、疏忽、严格责任引起的，还是由这些条款、任何内容和公司材料引起的或以任何方式与这些条款有关通过本公司使用或购买的任何产品或服务，包括但不限于收入或收入损失、资本损失、痛苦、情绪困扰或类似的损害，即使您已被告知这些损失。</p>
            <p>在任何情况下，Longway 由本协议引起或与本协议相关的总责任，无论是因违反合同、侵权（包括疏忽）引起的或与之相关的，或以其他方式超过支付给该协议的总金额6 个月预审</p>
            <p>在任何情况下，Longway由本协议引起或与本协议相关的总责任，无论是因违反合同、侵权（包括疏忽）引起的或与之相关的，或以其他方式超过支付给该协议的总金额引起索赔的事件发生前的 6 个月或 100
              美元，以较低者为准。即使您根据本协议采取的补救措施未能达到其基本目的，上述限制仍将适用。</p>
            <h4>10. 保障</h4>
            <p>您同意赔偿使公司、其所有者、子公司、公司附属公司、合资企业及其各自的董事、管理人员、股东、员工、代理人和承包商免受因所有索赔、要求、诉讼而造成的任何和所有损失、损害、损失、责任、罚款、命令、处罚、成本、判决和任何费用（包括任何合理的法律费用和支出）因为您的：</p>
            <p>( a ) 违反任何陈述或保证；</p>
            <p>( b ) 违反或未能遵守任何条款；</p>
            <p>( c ) 使用或依赖任何内容；</p>
            <p>( d ) 使用、访问或参与、在内容或公司材料上、通过、与内容或公司材料相关或与之相关的其他活动；</p>
            <p>( e ) 因您使用内容或公司材料或任何第三方平台或内容而侵犯任何第三方的权利或利益；</p>
            <p>( f ) 用户提交。</p>
            <p>我们将拥有使用我们选择的律师为任何索赔辩护和解决的唯一权利，您将在任何索赔的辩护和解决中合理地与我们合作。</p>
            <h4>11. 不可抗力</h4>
            <p>任何一方均不对因火灾、洪水、爆炸、战争、禁运、恐怖主义、劳资纠纷、政府行为、公共权力行为、流行病（包括 COVID-19 大流行）、内乱、停电、封锁、电力或交通设施短缺、天灾或任何其他无法控制的原因。</p>
            <h4>12.放弃</h4>
            <p>任何一方未能在任何时间或任何时期执行或行使任何​​条款或根据本条款的任何权利，不得解释为放弃任何此类条款或权利，并且绝不会影响该方以后的权利执行或行使它。</p>
            <h4>13.遣散费</h4>
            <p>如果任何具有司法管辖权的法院或行政机构认定任何条款无效或不可执行，则此类无效或不可执行将不会影响本条款的其他条款，这些条款将继续完全有效。双方同意尝试用有效或可执行的条款替代任何无效或不可执行的条款，以在最大程度上实现与无效或不可执行的条款相同的效果。</p>
            <h4>14.整个协议</h4>
            <p>这些条款将构成双方之间的完整理解.</p>
            <h4>15.适合法律和管辖区</h4>
            <p>Longway Capital LLC
              在美国境内拥有和经营。内容仅供居住在美国的人士使用。您承认并同意，如果您从美国以外的任何司法管辖区访问内容，则您全权负责确保您遵守您访问内容的该司法管辖区的所有和任何法律。我们不就其上可用的内容在美国境外使用是否适当、允许或合法作出任何声明，您使用该内容的风险由您自行承担。</p>
            <p>本协议将受美国适用的法律管辖。本协议项下或与内容相关的任何索赔将由美国华盛顿州有管辖权的法院解决。</p>
            <p>尽管有上述规定，您同意我们将被允许在任何司法管辖区就任何实际或涉嫌侵犯公司、公司关联公司或任何第三方的知识产权或其他专有权利的行为申请禁令救济。
              除非本协议另有规定，您同意因使用和提供内容而引起或与之相关的任何法律或衡平法上的争议或索赔，无论此类争议的产生日期如何，将由个人整体解决根据不时修订或替换的《仲裁法》的规定（非集体或集体）具有约束力的仲裁。仲裁费用，包括任何管理费、仲裁员费、律师费和支出，以及听证期间使用设施的费用，应由仲裁各方平均承担。可以在任何有管辖权的法院对任何裁决作出判决。仲裁员无权更改、修正、修改或更改任何条款，也无权授予条款禁止或根据适用法律不可用的任何补救措施。仲裁员无权根据公平原则作出任何裁决或给予任何救济。您同意对提交仲裁、仲裁程序、在仲裁程序中交换或产生的任何文件、为仲裁准备的任何摘要或其他文件以及仲裁裁决都将完全保密，不会向任何人披露。另一方，除非为执行任何仲裁裁决或双方的其他权利所必需，或法律或法院命令要求。</p>
          </div>
        </Modal>
      </section>
    </>
    )
  }
}
export default Subscribe;
