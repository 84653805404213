import React from 'react';
import HeaderComponent from "../../components/header";
import axios from "axios";
import {backendPath, frontendPath, getBackendUrl, getFrontendUrl, getQueryVariable, s3UrlBase} from "../../config/url";
import {message} from "antd";
import {headerHeight} from "../../config";
import FooterComponent from "../../components/footer";
import { LikeOutlined, MessageOutlined } from "@ant-design/icons";
import './article.css'

import Avatar from './pict/avatar.jpg';
import {dateToString} from "../../config/transform";
import {isLoggedIn} from "../../config/cookie";
import {StarComponent} from "../../components/star";

class Article extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      starID: this.props.article.star_id,
      starCount: this.props.article.star_count,
      commentCount: this.props.article.comment_count
    }
  }

  handleComment() {
    window.open(getFrontendUrl(frontendPath.article.replace("{0}", this.props.article.id)), "_self");
  }

  render() {
    const article = this.props.article;
    let avatar = Avatar;
    if(article.creator.avatar) {
      avatar = s3UrlBase + article.creator.avatar;
    }
    let nickName = article.creator.nick_name;
    let content = article.content;
    return(
      <div style={{width:"100%", height: 'auto', margin: '24px auto', padding:'16px 16px 8px', border:'2px solid #0000001a'}}>
        <div style={{paddingLeft:52, paddingRight:26, width:"100%", minHeight:100, position:'relative'}}>
          <div style={{width:40, height:40, borderRadius:40, overflow:'hidden', position:'absolute', top: 0, left: 0}}>
            <img src={avatar} style={{width:40, height:40}} alt="" />
          </div>
          <div style={{marginBottom:15}}>
            <span style={{fontWeight:600, lineHeight:'1.8rem', fontSize:18}}>{nickName}</span>
            &nbsp;&nbsp;
            <span style={{fontSize:15}}>{dateToString(new Date(article.add_time))}</span>
          </div>
          <h4>{article.title}</h4>
          <div className={'article-container'} dangerouslySetInnerHTML={{__html:content}}></div>
          {
            this.props.showAll ? <></> :
              <p>......{
                !article.to_subscribe || article.to_subscribe.length === 0 ?
                <a onClick={() => this.handleComment()}>查看全部 >></a> :
                  <a>订阅 [{article.to_subscribe.join("、")}] 后查看全部>></a>
              }</p>
          }
          <div style={{height:32, paddingTop:10, fontSize:18}}>
            <StarComponent isStar={this.state.starID !== null} id={this.props.article.id} starCount={this.props.article.star_count}/>
            <MessageOutlined />&nbsp;&nbsp;{this.state.commentCount}
          </div>
        </div>
      </div>
    )
  }
}
export default Article;
