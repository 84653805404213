import React from 'react';

import message from 'antd/lib/message'
import 'antd/lib/message/style'
import Icon from 'antd/lib/icon'
import 'antd/lib/icon/style'
import { LoadingOutlined } from "@ant-design/icons"
import Modal from 'antd/lib/modal'
import 'antd/lib/modal/style'
import axios from "axios";
import './index.css'
import {setCookie} from "../config/cookie";
import {backendPath, getBackendUrl} from "../config/url";
const registerModel = 'registerModel';



export class ResetModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cooldown: 0,
      processing: false,
    };
    this.handleCancel = this.handleCancel.bind(this);
  }
  handleCancel() {
    this.props.parent.setState({showModel: null});
  }
  handleRegister(usernameElementId, emailElementId, password1ElementId, password2ElementId, verifycodeElementId) {
    if(this.state.processing) {
      message.warning("数据处理中，请等待。");
      return ;
    }
    const email = document.getElementById(emailElementId).value;
    const password1 = document.getElementById(password1ElementId).value;
    const verifycode = document.getElementById(verifycodeElementId).value;
    let cur = this;
    this.setState({processing: true});
    let httpRequest = new XMLHttpRequest();
    httpRequest.open(
      backendPath.user.register.method,
      getBackendUrl(backendPath.user.register.path), true
    );
    let fd = new FormData();
    fd.append("email", email);
    fd.append("password", password1);
    fd.append('code', verifycode);
    httpRequest.send(fd);
    httpRequest.onreadystatechange = function () {
        if (httpRequest.readyState === 4) {
          if(httpRequest.status === 200) {
            const obj = JSON.parse(httpRequest.responseText);
            setCookie('token', obj['token']);
            message.success("重置成功");
            cur.props.parent.setState({showModel: null});
            setInterval(function () {
              window.location.reload();
            }, 1000);
          } else {
            const obj = JSON.parse(httpRequest.responseText);
            if(obj.hasOwnProperty('email')) {
              message.warning(obj['email']);
            }else if(obj.hasOwnProperty('username')) {
              message.warning(obj['username']);
            }else if(obj.hasOwnProperty('password')) {
              message.warning(obj['password']);
            }else if(obj.hasOwnProperty('code')) {
              message.warning(obj['code']);
            }
          }
          cur.setState({processing: false});
        }
    };
  }
  handleVerifyCodeRequest(emailElementId) {
    if(this.state.cooldown > 0) {
      message.warning("正在发送验证码，请稍后。");
      return ;
    }
    const email = document.getElementById(emailElementId).value;
    let cur = this, timer = null;
    let httpRequest = new XMLHttpRequest();
    httpRequest.open(backendPath.user.retrieveVerifyCode.method, getBackendUrl(backendPath.user.retrieveVerifyCode.path), true);
    let fd = new FormData();
    fd.append("email", email);
    httpRequest.send(fd);
    httpRequest.onreadystatechange = function () {
        if (httpRequest.readyState === 4) {
          const obj = JSON.parse(httpRequest.responseText);
          if(httpRequest.status === 200) {
            message.success(obj['msg']);
          } else {
            if(obj.hasOwnProperty('msg')) {
               message.warning(obj['msg']);
            } else {
              if(obj.hasOwnProperty('email')) {
                message.warning(obj['email']);
              } else if(obj.hasOwnProperty('username')) {
                message.warning(obj['username']);
              } else {
                message.warning('验证码发送失败');
              }
            }
            cur.setState({cooldown: 0});
            window.clearInterval(timer);
          }
        }
    };
    this.setState({cooldown: 60});
    timer = window.setInterval(function () {
      if(cur.state.cooldown === 0) {
        window.clearInterval(timer);
      } else if(cur.state.cooldown > 0) {
        cur.setState({cooldown: cur.state.cooldown - 1});
      }
    }, 1000);
  }
  render() {
    const modelWidth = 600;
    let visible = false;
    if(this.props.parent.state.showModel === 'resetModel') {
      visible = true;
    }
    return(
        <Modal visible={visible} footer={null} width={modelWidth}
               onCancel={this.handleCancel}>
          <div style={{textAlign:'center', color:'black', width:'100%', height:390}}>
            {/*title*/}
            <span style={{textAlign:'center', fontSize:21, fontFamily:'PingFangSC-Regular', display:'block'}}>
              <b>密&nbsp;&nbsp;码&nbsp;&nbsp;重&nbsp;&nbsp;置</b>
            </span>
            {/*邮箱*/}
            <div style={{marginTop:30, height:40, textAlign:'center', fontSize:20, opacity:0.8}}>
              <input autoComplete={'off'} className={'input-with-myborder'} placeholder={"邮箱"}
                     id={'reset-email'} type={'text'} style={{width:'100%'}}/>
            </div>
            {/*密码*/}
            <div style={{marginTop:30, height:40, textAlign:'center', fontSize:20, opacity:0.8}}>
              <input autoComplete={'off'} className={'input-with-myborder'} placeholder={"密码"}
                     id={'reset-password-1'} type={'password'} style={{width:'100%'}}/>
            </div>
            {/*验证码*/}
            <div style={{marginTop:30, height:40, textAlign:'center', fontSize:20, opacity:0.8}}>
              <input autoComplete={'off'} className={'input-with-myborder'} placeholder={"验证码"}
                     id={'reset-verifycode'} type={'text'} style={{width:'60%', display:'inline-block', float:'left'}}/>
              {/*<div style={{height:40, width:6, float:'left'}}></div>*/}
              <div style={{height:40, borderRadius:30, width:'20%', display:'table-cell', verticalAlign:'middle', textAlign:'center'}}>
                {
                  this.state.cooldown <= 0 ?
                    <a href="#" className="btn btn-medium" onClick={this.handleVerifyCodeRequest.bind(this, 'reset-email')}
                       style={{borderRadius:20, maxWidth:'95%', marginTop:-2, fontSize:14, fontFamily:'PingFangSC-Regular'}}
                    >
                      点击获取
                    </a>:
                    <a href="#" className="btn btn-medium"
                       style={{borderRadius:20, maxWidth:'95%', width:120, marginTop:-2.5, fontSize:14, fontFamily:'PingFangSC-Regular'}}
                    >
                      {this.state.cooldown}&nbsp;&nbsp;S
                    </a>
                }
              </div>
            </div>
            {/*注册按钮*/}
            <a href="#" className="btn btn-medium" style={{width:250, borderRadius:40, marginTop:50, fontSize:18, fontFamily:'PingFangSC-Regular'}}
              onClick={this.handleRegister.bind(this, 'reset-username', 'reset-email', 'reset-password-1',
                        'reset-password-2', 'reset-verifycode')}
            >
              {this.state.processing ? <><LoadingOutlined/>&nbsp;&nbsp;</>:<></>}重&nbsp;&nbsp;&nbsp;置
            </a>
          </div>
        </Modal>
    );
  }
}
