import React from 'react';
import HeaderComponent from "../../components/header";
import axios from "axios";
import {backendPath, getBackendUrl} from "../../config/url";
import {message} from "antd";
import {headerHeight} from "../../config";
import FooterComponent from "../../components/footer";
import banner1 from "../../img/slides/1.jpg";
import PlayerComponent from "./player";
import Subscribe from "../home/subscribe";
import Comment from "../../components/comment";



class Video extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    };
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  handleWindowResize = (e, h, w) => {
    // if(w < 1200) {w = 1200;}
    console.log(e, h, w);
    this.setState({windowHeight: h, windowWidth: w});
  };

  render() {
    // window.onresize = e => this.handleWindowResize(e, window.innerHeight, document.body.innerWidth);
    window.onresize = function () {
      console.log(window.innerHeight, document.body.clientWidth)
    }
    let videoDivHeight = 700, windowWidth = this.state.windowWidth;

    let playerWidth = windowWidth - 60;
    if(windowWidth > 900) {
      playerWidth = windowWidth * 0.8;
    } else if(windowWidth > 600) {
      playerWidth = windowWidth * 0.9;
    }
    playerWidth = Math.min(playerWidth, 1100);
    return(
      <>
        <HeaderComponent/>
        <div style={{background:'rgb(238, 238, 238)', width:'100%'}}>
          <PlayerComponent
            videoID={this.props.match.params.video_id}
            height={videoDivHeight}
            width={playerWidth}
          />
        </div>
        <a name="subscribe"></a>
        <Subscribe/>
        <FooterComponent/>
      </>
    )
  }
}
export default Video;
