import React from 'react';
import HeaderComponent from "../../components/header";
import axios from "axios";
import {backendPath, getBackendUrl} from "../../config/url";
import FooterComponent from "../../components/footer";
import Article from "../community/article";
import "../../css/loading.css"
import Comment from "../../components/comment";




class ArticlePage extends React.Component {
  constructor(props) {
    super(props);
    const id = this.props.match.params.article_id;
    this.state = {
      article: null
    };
    const current = this;
    axios.get(
      getBackendUrl(backendPath.article.retrieve.path.replace("{0}", id))
    ).then(function (response) {
      current.setState({article: response.data})
    });
  }
  render() {
    const article = this.state.article;
    return(
      <>
        <HeaderComponent/>
        <section className="section-padding" style={{background:'white', minHeight:'100%'}}>
          <div className="container" style={{minHeight: "100%"}}>
            <div style={{width:'90%', margin:'0 auto'}}>
              {
                article !== null ?
                  <>
                    <Article article={article} showAll={true}/>
                    {article.comment_permit ? <Comment id={this.props.match.params.article_id}/>: <></>}
                  </>:
                  <div className={'loading'} style={{marginBottom:"10em"}}></div>
              }
            </div>
          </div>
        </section>
        <FooterComponent/>
      </>
    )
  }
}
export default ArticlePage;
