import React from 'react';
import HeaderComponent from "../../components/header";
import axios from "axios";
import {backendPath, getBackendUrl} from "../../config/url";
import {message} from "antd";
import {headerHeight} from "../../config";
import FooterComponent from "../../components/footer";
import Banner from "./banner";
import Channel from "./channel";
import Subscribe from "./subscribe";
import defaultVideoCover from '../../img/default_video_cover.jpeg';
import {LiveList} from "../profile/liveList";


class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {channels: []};
    const current = this;
    axios.get(
      getBackendUrl(backendPath.channel.list.path)
    ).then(function (response) {
      current.setState({channels: response.data.result})
    })
  }
  render() {
    const channels = this.state.channels, channelSections = [];
    for(let channel of channels) {
      channelSections.push(<Channel channel={channel} index={channelSections.length} cols={4} className={"col-sm-3"}/>)
    }
    return(
      <>
        <HeaderComponent/>
        <Banner/>
        {
          channelSections.length === 0 ?
            <section id="content" style={{height:800}}>
              <div className={'loading'} style={{marginTop:100}}></div>
            </section>:
            channelSections
        }
        <a name="subscribe"></a>
        <Subscribe/>
        <FooterComponent/>
      </>
    )
  }
}
export default HomePage;
