import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/fancybox/jquery.fancybox.css";
import "../../css/flexslider.css";
import "../../css/style.css";
import {breakText} from "../../config/text";
import '../../index.css'
import { PlayCircleOutlined } from '@ant-design/icons';
import {backendPath, frontendPath, getBackendUrl, getFrontendUrl} from "../../config/url";

import defaultVideoCover from '../../img/default_video_cover.jpeg';

class NewVideos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videoList: []
    };
    const cur = this;
    let httpRequest = new XMLHttpRequest();
    httpRequest.open(backendPath.video.list.method, getBackendUrl(backendPath.video.list.path), true);
    httpRequest.withCredentials = true;
    httpRequest.send();
    httpRequest.onreadystatechange = function () {
      if (httpRequest.readyState === 4) {
        if (httpRequest.status === 200) {
          cur.setState({videoList: JSON.parse(httpRequest.responseText)['result']});
        }
      }
    };
  }
  render() {
    const cols = 4, videoList = this.state.videoList;
    const videoDisplay = [];
    for(let i = 0; i < videoList.length; i += cols) {
      const videoLine = [];
      for(let index = 0; index < cols && i + index < videoList.length; index ++) {
        const video = videoList[i + index];
        videoLine.push(
          <div className={"col-sm-3 md-margin-bottom-40"}>
            <div style={{position:'relative', cursor:'pointer'}}
                 onClick={() => window.open(
                    getFrontendUrl(frontendPath.video.replace('{0}', video.id).replace('{1}', "channel_id")
                   ), '_blank')
                 }
            >
              <img className="img-responsive" src={video.cover_url ? video.cover_url: defaultVideoCover} alt=""/>
              <div className={'video-hover'} style={{
                width:'100%', height:'100%', zIndex:2, position:'absolute', left:0, top:0, padding:"0 15px", opacity:0,
                background:'black', display: "table-cell", verticalAlign: 'middle', textAlign: 'center'
              }}>
                <p style={{position:'absolute', top:'50%', left:'50%', fontSize:44, margin: -22}}><PlayCircleOutlined/></p>
              </div>
            </div>
            <div style={{position:'relative'}}>
              <div style={{
                position:'absolute', right: 5, top: -30, width:60, background:'rgb(0, 0, 0, 0.8)', textAlign:'center',
                fontSize:16, fontWeight: 600, color:'white'
              }}>
                {Math.floor(video.duration / 60).toString() + ":" + (video.duration % 60).toString()}
              </div>
              <h3 style={{lineHeight:'23px', marginTop:5}}>{video.title}</h3>
            </div>
            <p style={{color:'black'}}>
              {video.days === 0 ? "今天更新" : video.days.toString() + "天前更新"}&nbsp;-&nbsp;{video.view_count}次观看
            </p>
          </div>
        )
      }
      videoDisplay.push(
        <div className="row service-v1" style={{marginBottom:10}}>{videoLine}</div>
      )
    }
    return(
      <section className="section-padding" style={{padding:50}}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title text-center">
                  <h2><span className="coloured">近期上线视频</span></h2>
                  <p>紧跟大盘走势，跟我Andy一起走。</p>
                </div>
              </div>
            </div>
            {videoDisplay}
          </div>
        </section>
    )
  }
}
export default NewVideos;
