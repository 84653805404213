import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../css/fancybox/jquery.fancybox.css";
import "../css/flexslider.css";
import "../css/style.css";



class FooterComponent extends React.Component {

  constructor(props){
    super(props);
  }

  render() {
    return (
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-sm-3">
              <div className="widget">
                <h5 className="widgetheading">Our Contact</h5>
                  <strong>Longway Capital LLC</strong><br/>
                  {/*JC Main Road, Near Silnile tower<br/>*/}
                  {/*Pin-21542 NewYork US.</address>*/}
                <p>
                  <i className="icon-phone"></i><br/>
                  <i className="icon-envelope-alt"></i> sharks_assistant@outlook.com
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="sub-footer">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <div className="copyright">
                  <p>
                    Copyright &copy; 2021.Longway Capital LLC All rights reserved.
                  </p>
                </div>
              </div>
              <div className="col-sm-6">
                <ul className="social-network">
                  <li>
                    <a href="https://twitter.com/andy_sharks" data-placement="top" title="Twitter">
                      <i className="fa fa-twitter">

                      </i>
                    </a>
                  </li>
                  {/*<li><a href="#" data-placement="top" title="Facebook"><i className="fa fa-facebook"></i></a></li>*/}
                  {/*<li><a href="#" data-placement="top" title="Linkedin"><i className="fa fa-linkedin"></i></a></li>*/}
                  {/*<li><a href="#" data-placement="top" title="Pinterest"><i className="fa fa-pinterest"></i></a></li>*/}
                  {/*<li><a href="#" data-placement="top" title="Google plus"><i className="fa fa-google-plus"></i></a></li>*/}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}



export default FooterComponent;
