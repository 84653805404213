import React from 'react';
import './index.css';
import {BrowserRouter, Switch, Redirect, Route} from "react-router-dom";

import HomePage from "./pages/home/home";
import ChannelPage from "./pages/channel/channel"
import Video from "./pages/video/video";
import ProfilePage from "./pages/profile/profile";
import CommunityPage from "./pages/community/community";
import ArticlePage from "./pages/article/article";

class Router extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return(
      <BrowserRouter>
        <Switch>
          <Route
            path={"/"}
            render={() => (
              <Switch>
                <Route path={"/home"} component={HomePage}/>
                <Route path={"/profile"} component={ProfilePage}/>
                <Route path={"/community"} component={CommunityPage}/>
                <Route path={"/article/:article_id"} component={ArticlePage}/>
                <Route path={"/channel/:channel_id"} component={ChannelPage}/>
                <Route path={"/video/:video_id"} component={Video}/>
                <Route render={() => <Redirect to={"/home"}/>}/>
              </Switch>
            )}
          />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default Router;

