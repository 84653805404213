import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../css/fancybox/jquery.fancybox.css";
import "../../css/flexslider.css";
import "../../css/style.css";
import banner1 from '../../img/slides/slide1.jpg';
import banner2 from '../../img/slides/slide2.jpg';
import {BigPlayButton, ControlBar, PlaybackRateMenuButton, Player, VolumeMenuButton} from "video-react";
// import banner1 from '../../img/slides/e.jpg';
// import banner2 from '../../img/slides/d.jpg';


class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      player: null,
      windowWidth: window.innerWidth
    };
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  handleWindowResize = (e, h, w) => {
    this.setState({windowWidth: w});
  };
  componentDidMount() {
    try {
      if(this.player !== null) this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    } catch (e) {

    }
  }

  handleStateChange(state) {
    this.setState({player: state});
  }
  render() {
    window.onresize = e => this.handleWindowResize(e, window.innerHeight, document.body.clientWidth);
    let style = {};
    if(this.state.windowWidth > 979) {
      style.margin = "0 auto"; style.width = '60%';
    } else if(this.state.windowWidth > 767) {
      style.margin = "0 auto"; style.width = '80%';
    } else if(this.state.windowWidth > 480) {
      style.margin = "0 auto"; style.width = '90%';
    } else {
      style.margin = "0 auto"; style.width = '100%';
    }
    return(
      <>
        <section id="banner">
          <div id="main-slider" className="flexslider">
            <ul className="slides">
              <li>
                <img src={banner2} alt=""/>
                <div className="flex-caption">
                  <h3></h3>
                </div>
              </li>
              <li>
                <img src={banner1} alt=""/>
                <div className="flex-caption">
                  <h3></h3>
                </div>
              </li>
            </ul>
          </div>
        </section>
        {/*<section id="content">*/}
          {/*<div style={style}>*/}
            {/*<Player  ref={player => {this.player = player;}}  playsInline src={*/}
              {/*"https://d39kdfcolhpeir.cloudfront.net/video_2021-07-22-21-02-57_30d56a2ceb3011eb8f42022499dc71ef"*/}
            {/*}*/}
                     {/*poster={*/}
                       {/*"https://d39kdfcolhpeir.cloudfront.net/cover_2021-07-22-23-58-16_aee8475aeb4811eba16d022499dc71ef"*/}
                     {/*}>*/}
              {/*<BigPlayButton position="center" />*/}
              {/*<ControlBar>*/}
                {/*<PlaybackRateMenuButton rates={[1.0, 1.25, 1.5, 2.0]} />*/}
                {/*<VolumeMenuButton vertical/>*/}
              {/*</ControlBar>*/}
            {/*</Player>*/}
          {/*</div>*/}
        {/*</section>*/}
      </>
    )
  }
}
export default Banner;
