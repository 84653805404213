import React from 'react';


import Modal from 'antd/lib/modal'
import 'antd/lib/modal/style'
import Icon from 'antd/lib/icon'
import 'antd/lib/icon/style'
import message from 'antd/lib/message'
import 'antd/lib/message/style'
import { LoadingOutlined, CameraOutlined } from "@ant-design/icons"

import './profile.css';
import {getCookie, isLoggedIn} from "../../config/cookie";
import {backendPath, getBackendUrl} from "../../config/url";


export class InfoResetModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      avatarOpacity: 0,
      newAvatar: null
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.beforeUpload = this.beforeUpload.bind(this);
    this.afterUpload = this.afterUpload.bind(this);
  }
  handleMouseEnter() {
    this.setState({avatarOpacity:0.8});
  }
  handleMouseLeave() {
    this.setState({avatarOpacity:0});
  }
  handleCancel() {
    this.props.parent.setState({showModal: null});
  }
  handleClick(e) {
    if(!isLoggedIn()) {
      return;
    }
    let ZhPattern = new RegExp("[\u4E00-\u9FA5]+");
    let nickName = document.getElementById('nickname').value;
    let nickNameLength = nickName.length;
    for(let i = 0; i < nickName.length; i ++) {
      if(ZhPattern.test(nickName.substr(i, 1))) {
        nickNameLength += 1;
      }
    }
    if(nickNameLength > 40) {
      message.warn("昵称过长。");
      return;
    }
    this.setState({
      processing: true,
    });
    let xmlhttp=new XMLHttpRequest();
    let fd = new FormData();
    if(this.state.newAvatar != null) {
      fd.append("avatar", this.state.newAvatar);
    }
    fd.append("nick_name", document.getElementById('nickname').value);
    fd.append("company", document.getElementById('company').value);
    fd.append("wechat", document.getElementById('wechat').value);
    xmlhttp.open(backendPath.user.updateInfo.method, getBackendUrl(backendPath.user.updateInfo.path), true);
    xmlhttp.withCredentials = true;
    xmlhttp.send(fd);
    let cur = this;
    xmlhttp.onreadystatechange = function(){
      if(xmlhttp.readyState === 4) {
        if(xmlhttp.status === 200 ) {
          message.success('更新成功');
          window.setTimeout(function () {
            window.location.reload()
          }, 2000)
        } else {
          const response = JSON.parse(xmlhttp.responseText);
          if(response.hasOwnProperty('avatar')) {
            message.warning(response['avatar']);
          } else if(response.hasOwnProperty('nick_name')) {
            message.warning(response['nick_name']);
          } else if(response.hasOwnProperty('school')) {
            message.warning(response['school']);
          } else if(response.hasOwnProperty('company')) {
            message.warning(response['company']);
          } else {
            message.warning('更新失败');
          }
        }
        cur.setState({
          processing: false
        });
      }
    };
  }
  beforeUpload() {
    document.querySelector('#avatar-upload').click()
  }
  afterUpload() {
    const cur = this;
    cur.setState({newAvatar: null});
    const avatar = document.querySelector('#avatar-upload').files[0];
    const isPict = (avatar === undefined || avatar.type === 'image/jpeg' || avatar.type === 'image/png');
    if (!isPict) {
      message.warning('请上传JPG/PNG图片');
      return ;
    }
    const isLt1M = avatar.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      message.warning('上传头像应小于1M');
      return ;
    }
    const reader = new FileReader();
    reader.onload = function (e) {
        const data = e.target.result;
        const image = new Image();
        image.onload=function(){
          if(image.width / image.height > 0.9 && image.width / image.height < 1.11) {
            cur.setState({newAvatar: avatar});
            message.success("头像加载完成，请确认上传")
          } else {
            message.warning("请上传像素比接近 1 的图片");
          }
        };
        image.src= data;
    };
    reader.readAsDataURL(avatar);
  }
  render() {
    const modelWidth = 600;
    const parent = this.props.parent;
    return (
      <Modal visible={this.props.parent.state.showModal === 'info'} footer={null} width={modelWidth} onCancel={this.handleCancel}>
        <div style={{textAlign:'center', color:'black', width:500, height:440, marginLeft:(modelWidth - 48 - 500) / 2}}>

          <div style={{width:100, height:100, borderRadius:100, marginLeft:200, marginTop:30,
            backgroundImage:`url(${parent.state.avatar})`, backgroundSize:'100% 100%', cursor:'pointer'}}>
            <div style={{width:100, height:100, background:'gray', borderRadius:100, opacity:this.state.avatarOpacity, cursot:'pointer'}}
                 onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
              <input type={'file'} id={'avatar-upload'} hidden={true} onChange={this.afterUpload}/>
              <CameraOutlined style={{fontSize:40, marginTop:30}} onClick={this.beforeUpload}/>
            </div>
          </div>

          {/*昵称*/}
          <div style={{marginTop:30, height:40, textAlign:'center', fontSize:20, opacity:0.8}}>
            <p style={{float:'left', width:80, marginBottom:0, marginTop:5}}>昵称：</p>
            <input className={'input-with-myborder'} defaultValue={parent.state.nickname}
                   id={'nickname'} type={'text'} style={{width:420, float:'left'}}/>
          </div>

          {/*公司*/}
          <div style={{marginTop:30, height:40, textAlign:'center', fontSize:20, opacity:0.8}}>
            <p style={{float:'left', width:80, marginBottom:0, marginTop:5}}>公司：</p>
            <input className={'input-with-myborder'} defaultValue={parent.state.company}
                   id={'company'} type={'text'} style={{width:420, float:'left'}}/>
          </div>

          {/*微信*/}
          <div style={{marginTop:30, height:40, textAlign:'center', fontSize:20, opacity:0.8}}>
            <p style={{float:'left', width:80, marginBottom:0, marginTop:5}}>学校：</p>
            <input className={'input-with-myborder'} defaultValue={parent.state.wechat}
                   id={'wechat'} type={'text'} style={{width:420, float:'left'}}/>
          </div>

          {/*确认按钮*/}
          <a href="#" className="btn btn-medium" style={{width:250, borderRadius:40, marginTop:50, fontSize:18, fontFamily:'PingFangSC-Regular'}}
            onClick={this.handleClick}
          >
            {this.state.processing ? <><LoadingOutlined/>&nbsp;&nbsp;</>:<></>}确&nbsp;&nbsp;&nbsp;认
          </a>

        </div>
      </Modal>
    )
  }
}
