import React from 'react';

import message from 'antd/lib/message'
import 'antd/lib/message/style'
import Icon from 'antd/lib/icon'
import 'antd/lib/icon/style'
import Modal from 'antd/lib/modal'
import 'antd/lib/modal/style'
import { LoadingOutlined } from "@ant-design/icons"



import Input from 'antd/lib/input'
import 'antd/lib/input/style'
import {setCookie} from "../config/cookie";

import './index.css'
import {backendPath, getBackendUrl} from "../config/url";


const loginModel = 'loginModel', retrievePwdModel = 'resetModel';


export class LoginModel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
    };
    this.handleCancel = this.handleCancel.bind(this);
    this.handleRetrievePwd = this.handleRetrievePwd.bind(this);
  }
  handleCancel() {
    this.props.parent.setState({showModel: null});
  }
  handleRetrievePwd() {
    this.props.parent.setState({showModel: retrievePwdModel});
  }
  handleLogin(emailElementId, passwordElementId) {
    if(this.state.processing) {
      message.warning("数据处理中，请等待。");
      return ;
    }

    const email = document.getElementById(emailElementId).value;
    const password = document.getElementById(passwordElementId).value;

    this.setState({processing: true});
    let httpRequest = new XMLHttpRequest();
    httpRequest.open(backendPath.user.login.method, getBackendUrl(backendPath.user.login.path), true);
    let fd = new FormData();
    fd.append("username", email);
    fd.append("password", password);
    httpRequest.send(fd);
    let cur = this;
    httpRequest.onreadystatechange = function () {
        if (httpRequest.readyState === 4) {
          if (httpRequest.status === 200) {
            const obj = JSON.parse(httpRequest.responseText);
            setCookie('token', obj['token']);
            cur.props.parent.setState({showModel: null, login: true});
            window.location.reload();
          } else if (httpRequest.status === 400) {
            message.warning("邮箱不存在或密码错误");
          } else {
            message.warning("抱歉：服务器错误");
          }
          cur.setState({processing: false});
        }
    };
  }

  render() {
    const modelWidth = 600, modelMargin = 30;

    let visible = false;
    if(this.props.parent.state.showModel === loginModel) {
      visible = true;
    }

    return(
      <Modal visible={visible} footer={null} width={modelWidth}
               onCancel={this.handleCancel}>
          <div style={{textAlign:'center', color:'black', width:'100%', height:320}}>
            {/*title*/}
            <span style={{textAlign:'center', fontSize:21, fontFamily:'PingFangSC-Regular'}}><b>登&nbsp;&nbsp;录</b></span>
            {/*邮箱*/}
            <div style={{marginTop:30, height:40, textAlign:'center', fontSize:20, opacity:0.8}}>
              <input autoComplete={'off'} className={'input-with-myborder'} placeholder={"邮箱"}
                     id={'login-email'} type={'text'} style={{width:'100%'}}/>
            </div>
            {/*密码*/}
            <div style={{marginTop:30, height:40, textAlign:'center', fontSize:20, opacity:0.8}}>
              <input autoComplete={'off'} className={'input-with-myborder'} placeholder={"密码"}
                     id={'login-password'} type={'password'} style={{width:'100%'}}/>
            </div>
            {/*登录按钮*/}
            <a href="#" className="btn btn-medium" style={{width:250, maxWidth:'100%', borderRadius:40, marginTop:50, fontSize:18, fontFamily:'PingFangSC-Regular'}}
              onClick={this.handleLogin.bind(this, 'login-email', 'login-password')}
            >
              {this.state.processing ? <><LoadingOutlined/>&nbsp;&nbsp;</>:<></>}登&nbsp;&nbsp;&nbsp;录
            </a>

            <div style={{marginLeft:'15%', marginRight:'15%', textAlign:'center', width:'70%', marginTop:modelMargin}}>
              <a onClick={this.handleRetrievePwd} style={{fontSize:18}}>忘记密码？</a>
            </div>

          </div>
        </Modal>
    );
  }
}
