import {settings, env} from "../config";

export const backendUrlBase = settings[env].backendUrlBase;
export const frontendUrlBase = settings[env].frontendUrlBase;

export const s3UrlBase = settings[env].s3UrlBase;

export const frontendPath = {
  login: "/login",
  home: "/home",
  profile: "/profile",
  community: "/community",
  video: "/video/{0}?channel={1}",
  channel: "/channel/{0}",
  article: "/article/{0}"
};
// r'user', UserViewset, base_name="user")
// router.register(r'pwd-reset', PwdResetUserViewset, base_name="pwd-reset")
// router.register(r'register-verifycode', RegisterVerifyCodeViewset, base_name="register-verifycode")
// router.register(r'pwd-retrieve-verifycode'
export const backendPath = {
  user: {
    getInfo: {path: "/user/1/", method: 'GET'},
    updateInfo: {path: "/user/1/", method: 'PUT'},
    resetPwd: {path: '/pwd-reset/1/', method: 'PUT'},
    register: {path: "/user/", method: 'POST'},
    retrieveVerifyCode: {path: "/register-verifycode/", method: 'POST'},
    login: {path: "/login/", method: 'POST'}
  },
  channel: {
    list: {path: "/channel/", method: 'GET'},
    retrieve: {path: "/channel/{0}/", method:'GET'}
  },
  video: {
    retrieve: {path: "/video/{0}/", method:'GET'},
    list: {path: "/video/", method:'GET'}
  },
  subscription: {
    list: {path: "/subscription/", method: 'GET'},
  },
  order: {
    list: {path: "/order/", method: 'GET'},
    subscribe: {path: "/order/", method: 'POST'},
  },
  donate: {
    create: {path: "/donate/", method: "POST"}
  },
  live: {
    list: {path: "/live/", method: "GET"}
  },
  article: {
    list: {path: "/article/", method: "GET"},
    retrieve: {path: "/article/{0}", method: "GET"}
  },
  star: {
    do: {path: "/star/", method: "POST"},
    undo: {path: "/star/{0}/", method: "DELETE"}
  },
  comment: {
    list: {path: "/comment/?receiver={0}", method: 'GET'},
    create: {path: "/comment/", method: 'POST'}
  }
};

export function getBackendUrl(path) {
  return backendUrlBase + path;
}

export function getFrontendUrl(path) {
  return frontendUrlBase + path;
}

export function getQueryVariable(variable)
{
       let query = window.location.search.substring(1);
       let vars = query.split("&");
       for (let i=0;i<vars.length;i++) {
               let pair = vars[i].split("=");
               if(pair[0] === variable){return decodeURI(pair[1]);}
       }
       return null;
}
