import React from 'react';

export function breakText(text) {
  const result = [];
  for(let line of text.split("\n")) {
    if(result.length > 0) result.push(<br/>);
    result.push(line);
  }
  return result;
}
