export function checkNumber(num) {
  const reg = /^\d+$/;
  if(!reg.test(num)) {
    return "不是正整数";
  }
  return null;
}

export function caculateLocalTime(seattleTime) {
  // const seattleTime = {year:2020, month:2, day:8, hours:12, minutes:0, seconds:0, ms:0}; // UTC-8
  const seattleOffset = 0, curOffset = (new Date()).getTimezoneOffset();
  const deltaOffset = curOffset - seattleOffset;
  let date = new Date(seattleTime.year, seattleTime.month - 1, seattleTime.day , seattleTime.hours, seattleTime.minutes, seattleTime.seconds, seattleTime.ms);
  return new Date(date.getTime() - deltaOffset * 60000);
}

export function dateToString(d) {
  return d.getFullYear() + '/' +( d.getMonth() + 1) + '/' + d.getDate() + "-" + d.getHours() + ":" + d.getMinutes();
}

export function stringToTime(s) {
  let l = s.split('/');
  if(l.length !== 5) return null;
  if(checkNumber(l[0]) != null || checkNumber(l[1]) != null || checkNumber(l[2]) != null ||
    checkNumber(l[3]) != null || checkNumber(l[4]) != null) {
    return null;
  }
  return l[0] + "-" + l[1] + "-" + l[2] + "T" + l[3] + ":" + l[4] + "Z";
}

export function timeToString(d) {
  const reg = /^(\d+)-(\d+)-(\d+)T(\d+):(\d+):(\d+)Z$/;
  if(!reg.test(d)) {
    return null;
  }
  return RegExp.$1 + '/' + RegExp.$2 + '/' + RegExp.$3 + "/" + RegExp.$4 + "/" + RegExp.$5;
}

